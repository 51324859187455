import React, { useState, useEffect } from 'react';
import { Table, Chip, Loader, Alert, Container, Text, Paper, Button, CheckIcon } from '@mantine/core';
import axios from 'axios';
import { getServerUrl } from '../../../../config/constants';
import { BsClock } from 'react-icons/bs';

interface TransactionReport {
  id: number;
  transaction_date: string;
  transaction_name: string;
  transaction_category: string;
  message: string;
  status: 'pending' | 'processed';
}

const AccTransactionReports: React.FC = () => {
  const [reports, setReports] = useState<TransactionReport[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userid');

      if (!userId) {
        throw new Error('User ID not found in URL');
      }

      const response = await axios.get<TransactionReport[]>(`${getServerUrl()}/transaction-reports-list/?userid=${userId}`);
      setReports(response.data);
      setLoading(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      setLoading(false);
    }
  };

  const handleMarkAsCompleted = async (id: number) => {
    try {
      setLoading(true);
      await axios.patch(`${getServerUrl()}/transaction-reports/${id}/`, { status: 'processed' });
      // Refresh the reports after successful update
      await fetchReports();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(JSON.stringify(err.response.data));
      } else {
        setError(err instanceof Error ? err.message : 'Failed to update status');
      }
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container size="md" py="xl">
        <Loader size="lg" variant="dots" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container size="md" py="xl">
        <Alert title="Error" color="red">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container size="xl" py="xl">
      <Paper shadow="xs" p="md">
        <Text size="xl" fw={700} mb="md">Transaction Reports</Text>
        <Table striped highlightOnHover  withColumnBorders>
          <thead>
            <tr>
              <th>Transaction Date</th>
              <th>Transaction Name</th>
              <th>Category</th>
              <th>Message</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                <td>{report.transaction_date}</td>
                <td>{report.transaction_name}</td>
                <td>{report.transaction_category}</td>
                <td>{report.message}</td>
                <td>
                  
                  <Button
                    variant="light"
                    color={report.status === 'processed' ? 'green' : 'yellow'}
                    leftSection={report.status === 'processed' ? <CheckIcon size={14} /> : <BsClock size={14} />}
                    size="sm"
                  >
                    {report.status.charAt(0).toUpperCase() + report.status.slice(1)}
                  </Button>
                </td>
                <td>
                  {report.status === 'pending' && (
                    <Button
                      size="sm"
                      variant="light"
                      color="blue"
                      onClick={() => handleMarkAsCompleted(report.id)}
                    >
                      Mark as Completed
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>
    </Container>
  );
};

export default AccTransactionReports;