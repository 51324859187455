import React, { useState, useEffect } from "react";
import { signIn } from "./hooks";
import logo from "../../assets/logo.png";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import background from "../../assets/backgrounds/background-outss.webp";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      window.location.href = "/dashboard";
    }
  }, []);

  const validate = () => {
    if (formData.email === "" || formData.password === "") {
      setError("Please fill all the fields");
      return false;
    }
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (isLoading) return;
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      signIn({ email: formData.email, password: formData.password })
        .then((response) => {
          console.log("API call success:", response);
          if (response.success) {
            localStorage.setItem("userId", response.user.toString());
            localStorage.setItem("token", response.token);
            localStorage.setItem("mailId", formData.email);
            localStorage.setItem("signin response", JSON.stringify(response));
            window.location.href = "/dashboard/";
          } else {
            if (response.response) {
              setError(response.response);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("API call failed:", error);
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "black",
          opacity: 0.1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      ></div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Outss Logo" />
        <h2 className="mt-6 text-center text-xl leading-9 font-extrabold text-gray-900">
          Login to Your Account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  onClick={() => setError("")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  onClick={() => setError("")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-0 top-0 mt-3 mr-4 text-gray-400 hover:text-gray-500"
                >
                  {showPassword ? (
                    <RiEyeOffFill className="h-5 w-5" />
                  ) : (
                    <RiEyeFill className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

            <div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {isLoading ? "Signing in..." : "Sign in"}
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm leading-5">
                <a
                  href="/signup"
                  className="font-medium text-primary hover:text-primary focus:outline-none focus:underline"
                >
                  Not a member? Sign up
                </a>
              </div>
              <div className="text-sm leading-5">
                <a
                  href="/forgot-password/"
                  className="font-medium text-primary hover:text-primary focus:outline-none focus:underline"
                >
                  Forgot password?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;