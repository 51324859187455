import React, { useCallback, forwardRef } from 'react';
import {
  ReactionSelector,
  useMessageContext,
  useChannelStateContext,
  ReactionSelectorProps,
  DefaultStreamChatGenerics
} from 'stream-chat-react';

export const CustomReactionSelector = forwardRef<
  HTMLDivElement,
  ReactionSelectorProps<DefaultStreamChatGenerics>
>((props, ref) => {
  const { message } = useMessageContext<DefaultStreamChatGenerics>();
  const { channel } = useChannelStateContext<DefaultStreamChatGenerics>();

  const handleReaction = useCallback(async (reactionType: string) => {
    if (!message.id) return;

    try {
      const existingReaction = message.own_reactions?.find(reaction => reaction.type === reactionType);

      if (existingReaction) {
        // If the same reaction is clicked again, remove it
        await channel.deleteReaction(message.id, reactionType);
      } else {
        // Remove all existing reactions from the user
        const existingReactions = message.own_reactions || [];
        for (const reaction of existingReactions) {
          await channel.deleteReaction(message.id, reaction.type);
        }

        // Add the new reaction
        await channel.sendReaction(message.id, { type: reactionType });
      }
    } catch (error) {
      console.error('Error handling reaction:', error);
    }
  }, [message, channel]);

  return (
    <ReactionSelector
      {...props}
      handleReaction={handleReaction}
      ref={ref}
    />
  );
});

CustomReactionSelector.displayName = 'CustomReactionSelector';

// Use the default ReactionsList component
export { ReactionsList as CustomReactionsList } from 'stream-chat-react';