import React from 'react';

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStartTour: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onClose, onStartTour }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Welcome to Your Outss Dashboard!</h2>
        <p className="text-gray-600 mb-6">
          We're excited to have you here. Let's take a quick tour to help you get familiar with your new dashboard.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition-colors"
          >
            Maybe Later
          </button>
          <button
            onClick={onStartTour}
            className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary transition-colors"
          >
            Start Tour
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;