import React, { useState, useEffect } from 'react';
import { FaCheck, FaClock, FaInfoCircle, FaLightbulb, FaUpload } from 'react-icons/fa';
import { getApiBaseUrl } from '../../../../config/constants';

interface BookkeepingStatusData {
  month: number;
  status: string;
  amount: string | null;
}

const BookkeepingStatus = () => {
  const [apiData, setApiData] = useState<BookkeepingStatusData[]>([]);
  const [hoveredMonth, setHoveredMonth] = useState<string>("");

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId') || '1';
        const year = new Date().getFullYear();
        const response = await fetch(`${getApiBaseUrl()}/bookkeeping-status/2/?year=${year}`);
        const data = await response.json();
        if (Array.isArray(data)) {
          setApiData(data);
        } else {
          setApiData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setApiData([]);
      }
    };

    fetchData();
  }, []);

  const currentMonth = new Date().getMonth();

  const getStatusFromData = (index: number): string => {
    const monthData = apiData.find(item => item.month === index + 1);
    if (currentMonth === index && !monthData) {
      return 'in-progress';
    }
    return monthData ? monthData.status : '';
  };

  const getAmountFromData = (month: string): string => {
    const monthIndex = months.indexOf(month) + 1;
    const monthData = apiData.find(item => item.month === monthIndex);
    return monthData && monthData.amount ? monthData.amount : 'No data';
  };

  const status = months.map((month, index) => ({
    month,
    status: getStatusFromData(index),
  }));

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'complete':
        return <FaCheck className='text-[#34D399]' size={20}/>
      case 'ready':
        return <FaLightbulb className="text-[#3B82F6]" />;
      case 'in-progress':
        return <FaClock className="text-[#F59E0B]" />;
      case 'input-needed':
        return <FaInfoCircle className="text-[#EF4444]" />;
      default:
        return <span></span>;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'complete':
        return 'bg-[#34D399]';
      case 'ready':
        return 'bg-[#3B82F6]';
      case 'in-progress':
        return 'bg-[#F59E0B]';
      case 'input-needed':
        return 'bg-[#EF4444]';
      default:
        return 'bg-gray-200';
    }
  };

  return (
    <div className="bg-white border border-gray-200 shadow-sm rounded-lg p-4 mx-5 md:mx-0">
      <div className="text-xl font-bold mb-4">Bookkeeping Status</div>
      <div className="grid grid-cols-6 md:grid-cols-12 gap-4">
      {status.map((month) => (
  <div
    key={month.month}
    className="flex flex-col items-center relative"
    onMouseEnter={() => setHoveredMonth(month.month)}
    onMouseLeave={() => setHoveredMonth("")}
  >
    <div className='mb-2'>{month.month.slice(0, 3)}</div>
    <div className={`w-8 h-8 rounded-full flex items-center justify-center border`}>
      {getStatusStyle(month.status)}
      {hoveredMonth === month.month && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-4 bg-white border border-gray-200 rounded-lg shadow-lg p-3 z-10 flex flex-col">
          <div className="text-gray-700 font-semibold mb-1">{month.month}</div>
          <div className="text-green-600 text-lg outssnumbers">
            {getAmountFromData(month.month) !== 'No data' 
              ? `$${getAmountFromData(month.month)}` 
              : 'No data'}
            
          </div>
          <div className="text-green-600 font-bold">
            {month.status}
          </div>
        </div>
      )}
    </div>
  </div>
))}
      </div>
      <div className="flex flex-wrap gap-3 items-center justify-start mt-6 md:items-start sm:items-start">
        <div className="flex items-center space-x-3">
          <FaCheck className='text-[#34D399]' size={20}/>
          <h4 className="text-gray-700">Complete</h4>
        </div>
        {/* <div className="flex items-center space-x-3">
          <FaLightbulb className='text-[#3B82F6]' size={20}/>
          <h4 className="text-gray-700">Ready for Insights</h4>
        </div> */}
        <div className="flex items-center space-x-3">
          <FaClock className='text-[#F59E0B]' size={20}/>
          <h4 className="text-gray-700">In Progress</h4>
        </div>
        <div className="flex items-center space-x-3">
          <FaInfoCircle className='text-[#EF4444]' size={20} />
          <h4 className="text-gray-700">Your Input Needed</h4>
        </div>
      </div>
      
      <a href="/dashboard/mybooks/">
        <button className='bg-primary text-white px-4 py-2 rounded mt-4 flex'>
          <FaUpload className='mr-2' />
          Upload Documents
        </button>
      </a>
    </div>
  );
};

export default BookkeepingStatus;