
 const getHumanReadableDate = (date: Date): string => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  return formattedDate;

  // if (date.toDateString() === today.toDateString()) {
  //   return 'today';
  // } else if (date.toDateString() === tomorrow.toDateString()) {
  //   return 'tomorrow';
  // } 
  
  // else {
  //   return date.toLocaleDateString('en-US');
  // }
};


export { getHumanReadableDate };