import React, { useState, useEffect } from 'react';
import { getApiBaseUrl } from '../../config/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface BankConnection {
  id: number;
  institution_name: string;
  account_name: string;
  account_type: string;
  account_subtype: string;
}

interface Transaction {
  id: string;
  date: string;
  description: string;
  amount: number;
  category: string[];
}

interface TransactionResponse {
  transactions: Transaction[];
  total_transactions: number;
  total_pages: number;
  current_page: number;
  has_next: boolean;
  has_previous: boolean;
}

interface BankStatementViewProps {
  userId: string;
}
const BankStatementView: React.FC<BankStatementViewProps> = ({ userId }) => {
  const [bankConnections, setBankConnections] = useState<BankConnection[]>([]);
  const [selectedConnection, setSelectedConnection] = useState<BankConnection | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [perPage] = useState<number>(20);

  useEffect(() => {
    fetchBankConnections();
  }, [userId]);

  const fetchBankConnections = async (): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${getApiBaseUrl()}/plaid/bank-connections/${userId}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Include any authentication headers if needed
        },
      });
      if (!response.ok) throw new Error('Failed to fetch bank connections');
      const data: BankConnection[] = await response.json();
      setBankConnections(data);
    } catch (err) {
      setError('Error fetching bank connections');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransactions = async (connectionId: number, page: number): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        start_date: startDate?.toISOString().split('T')[0] || '',
        end_date: endDate?.toISOString().split('T')[0] || '',
        page: page.toString(),
        per_page: perPage.toString(),
      });

      const response = await fetch(`${getApiBaseUrl()}/plaid/transactions/${userId}/${connectionId}/?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Include any authentication headers if needed
        },
      });
      if (!response.ok) throw new Error('Failed to fetch transactions');
      const data: TransactionResponse = await response.json();
      
      // Ensure transactions is an array
      setTransactions(Array.isArray(data.transactions) ? data.transactions : []);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
    } catch (err) {
      setError('Error fetching transactions');
      console.error(err);
      setTransactions([]); // Reset transactions to an empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleConnectionSelect = (connection: BankConnection): void => {
    setSelectedConnection(connection);
    setCurrentPage(1);
    fetchTransactions(connection.id, 1);
  };

  const handlePageChange = (newPage: number): void => {
    if (selectedConnection) {
      setCurrentPage(newPage);
      fetchTransactions(selectedConnection.id, newPage);
    }
  };

  const handleDateChange = (): void => {
    if (selectedConnection) {
      setCurrentPage(1);
      fetchTransactions(selectedConnection.id, 1);
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };


  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Bank Statements</h1>
      
      {loading && <p className="text-gray-600">Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      
      {!loading && !error && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-1">
            <h2 className="text-xl font-semibold mb-2">Your Bank Connections</h2>
            {bankConnections.length === 0 ? (
              <p className="text-gray-600">No bank connections found.</p>
            ) : (
              <ul className="space-y-2">
                {bankConnections.map((connection) => (
                  <li 
                    key={connection.id}
                    className={`p-2 rounded cursor-pointer ${selectedConnection?.id === connection.id ? 'bg-blue-100' : 'bg-gray-100 hover:bg-gray-200'}`}
                    onClick={() => handleConnectionSelect(connection)}
                  >
                    {connection.institution_name} - {connection.account_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          
          <div className="col-span-2">
            {selectedConnection && (
              <div>
                <h2 className="text-xl font-semibold mb-2">
                  Transactions for {selectedConnection.institution_name} - {selectedConnection.account_name}
                </h2>
                <div className="mb-4 flex space-x-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    />
                  </div>
                  <button
                    onClick={handleDateChange}
                    className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Apply
                  </button>
                </div>
                {transactions.length === 0 ? (
                  <p className="text-gray-600">No transactions found for the selected period.</p>
                ) : (
                  <>
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-200">
                          <th className="p-2 text-left">Date</th>
                          <th className="p-2 text-left">Description</th>
                          <th className="p-2 text-right">Amount</th>
                          <th className="p-2 text-left">Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {transactions.map((transaction) => (
                          <tr key={transaction.id} className="border-b">
                            <td className="p-2">{transaction.date}</td>
                            <td className="p-2">{transaction.description}</td>
                            <td className={`p-2 text-right ${transaction.amount < 0 ? 'text-red-500' : 'text-green-500'}`}>
                              ${Math.abs(transaction.amount).toFixed(2)}
                            </td>
                            <td className="p-2">{transaction.category.join(', ')}</td>
                          </tr>
                        ))} */}
                      </tbody>
                    </table>
                    <div className="mt-4 flex justify-between items-center">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
                      >
                        Previous
                      </button>
                      <span>Page {currentPage} of {totalPages}</span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {!selectedConnection && (
              <p className="text-gray-600">Select a bank connection to view transactions.</p>
            )}
          </div>

        </div>
      )}
    </div>
  );
};

export default BankStatementView;