import React, { useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import logo from "../../../assets/logo.png";
import { getApiBaseUrl } from "../../../config/constants";
import mixpanel from "mixpanel-browser";

// const stripePromise: Promise<Stripe | null> = loadStripe(
//   "pk_live_51OxqKHCueHCcnvZ1HBf9GDa22RZyWcOx1S4545GmW4gUtqa0x4hOtM8OdU7urSuIIClkJXdeZTg0vHol0OiH278F00dmSP2D2C"
// );

const stripePromise: Promise<Stripe | null> = loadStripe(
    "pk_test_51OxqKHCueHCcnvZ13syBQqgfHb87OQK5EkbEVu57JNHqTBfk8dkQsI27yaIDhen0OLfmJtXpJh9Db6Ld1OP4FdfU00jdZGo4S4"
  );

const TrialActivationForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const mailId = localStorage.getItem("mailId");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    mixpanel.track('Card Added Visited');
    mixpanel.track('Trial Activation Button Clicked in Billing');
    e.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      setError("Stripe has not loaded. Please try again.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Card element not found. Please try again.");
      setProcessing(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message ?? "An error occurred.");
      setProcessing(false);
      return;
    }

    // Get the user ID from local storage
    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError("User ID not found in local storage.");
      setProcessing(false);
      return;
    }

    try {
      const response = await fetch(
        `${getApiBaseUrl()}/trial/activate/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user: userId, token: paymentMethod.id }),
        }
      );

      if (response.ok) {
        // Trial activation successful, show the popup
        mixpanel.track('Trial Activation Visited',{
          email: mailId,
          platform: platform,
        });
        setShowPopup(true);
        setTimeout(() => {
          // Redirect to the dashboard after 3 seconds
          window.location.href = "/dashboard";
        }, 3000);
      } else {
        const data = await response.json();
        setError(data.response || "An error occurred. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }

    setProcessing(false);
  };

  // Function to detect the platform
  function detectPlatform() {
    var userAgent = navigator.userAgent || navigator.vendor ;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
        return "mobile";
    }
    return "web";
  }

  // Store the platform
  var platform = detectPlatform();

  console.log('platform', platform);

  return (
    <div className="max-w-md mx-auto mt-12">
      {showPopup && (
        <>
          <div className="fixed inset-0 bg-black opacity-90 z-40"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-md p-8">
              <div className="flex items-center justify-center mb-4">
                <svg
                  className="w-12 h-12 text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <p className="text-xl font-semibold text-center">
                Trial Activated!
              </p>
              <p className="text-gray-600 text-center mt-2">
                Redirecting to the dashboard...
              </p>
            </div>
          </div>
        </>
      )}
      <div className="bg-white rounded-lg shadow-md p-8 border">
        <img src={logo} alt="outsslogo" className="h-12 mx-auto mb-6" />
        <h2 className="text-2xl font-semibold text-center mb-6">
          Start Your 30-Day Free Trial
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label
              htmlFor="card-element"
              className="block text-gray-700 font-medium mb-2"
            >
              Credit Card
            </label>
            <CardElement
              id="card-element"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
              className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {error && <div className="text-red-500 mb-6">{error}</div>}
          <button
            type="submit"
            
            disabled={!stripe || processing}
            className="w-full bg-primary hover:bg-primary text-white font-semibold py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            {processing ? "Processing..." : "Activate Free Trial"}
          </button>
        </form>
        <div className="mt-6">
          <p className="text-gray-600 text-center">
            Enjoy all features for{" "}
            <span className="font-semibold">30 days</span> without any charges.
          </p>
          <p className="text-gray-600 text-center mt-2">
            After the trial, you will be charged based on your usage.
          </p>
        </div>
        <p className="mt-6 text-gray-500 text-sm text-center">
          By activating the free trial, you agree to our terms and conditions.
        </p>
      </div>
    </div>
  );
};

const TrialActivation: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <TrialActivationForm />
    </Elements>
  );
};

export default TrialActivation;