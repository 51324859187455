import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiBaseUrl } from "../../../config/constants";
import ChatWindowAccountant from "../../../financial-management/pages/ChatWindow/ChatWindowAccountant";
import ChatWindowSuccessManager from "../../../financial-management/pages/ChatWindow/ChatWindowSuccessManager";
import { FormData } from "../../../pages/OnBoarding/hooks";
import BankStatements from "../BankStatements";
import AccTransactionReports from "../ClientTabs/AccTransactionReports";
import CreateInvoice from "../CreateInvoice";
import WorkMailAccountDisplay from "../WorkMailAccountDisplay";
import StreamChatAccountant from "../StreamChatAccountant";
import AccountantPlaidView from "../AccountantPlaidView";
import '../DashboardRoot/dashboard.css';

const ClientsView: React.FC = () => {
  const [clientData, setClientData] = useState<FormData | null>(null);
  const [activeTab, setActiveTab] = useState("profile");

  const { id } = useParams<{ id: string }>();
  console.log("id:", id);

  useEffect(() => {
    const fetchClientData = async () => {
      console.log("fetching client data...", id);
      try {
        const response = await axios.get<FormData>(
          `${getApiBaseUrl()}/business-details/${id}`
        );
        setClientData(response.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, [id]);

  if (!clientData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white min-h-screen px-3">
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-6 title">{clientData.businessName}</h1>
        <div className="bg-white rounded-lg">
          <ul className="flex border-b border-gray-300">
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "profile"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("profile")}
            >
              Profile
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "bankStatements"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("bankStatements")}
            >
              Bank Statements
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "plaidStatements"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("plaidStatements")}
            >
              Plaid Statements
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "inbox"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("inbox")}
            >
              Inbox
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "success"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("success")}
            >
              Success Manager
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "bills"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("bills")}
            >
              Bills
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "reports"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("reports")}
            >
              Transation Reports
            </li>
            <li
              className={`px-4 py-2 cursor-pointer ${
                activeTab === "createemail"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("createemail")}
            >
              Create Email
            </li>
          </ul>
          <div className="">
            {activeTab === "profile" && (
              <div className="grid grid-cols-2 gap-6">
                {/* Profile tab content */}
                <div>
                  <p className="font-bold mb-2">Business name:</p>
                  <p>{clientData.businessName}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Legal Structure:</p>
                  <p>{clientData.legalStructure}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Industry Type:</p>
                  <p>{clientData.industryType}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Business Model:</p>
                  <p>{clientData.businessModel}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Accounting Software:</p>
                  <p>{clientData.accountingSoftware}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Chart of Accounts:</p>
                  <p>{clientData.chartOfAccounts ? "Yes" : "No"}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Tax ID Number:</p>
                  <p>{clientData.taxIdNumber}</p>
                </div>
                <div>
                  <p className="font-bold mb-2">Address:</p>
                  <p>{clientData.address}</p>
                </div>
              </div>
            )}
            {activeTab === "bankStatements" && (
              <div>
                {/* Bank Statements tab content */}
                <BankStatements />
              </div>
            )}
            {activeTab === "plaidStatements" && (
              <div>
                {/* Bank Statements tab content */}
                <AccountantPlaidView />
              </div>
            )}
            {activeTab === "inbox" && (
              <div>
                {/* Inbox tab content */}
                {/* <ChatWindowAccountant />
                 */}
                 <StreamChatAccountant />
              </div>
            )}
            {activeTab === "success" && (
              <div>
                {/* Bills tab content */}
                <ChatWindowSuccessManager />
              </div>
            )}
            {activeTab === "bills" && (
              <div>
                {/* Bills tab content */}
                <CreateInvoice />
              </div>
            )}
            {activeTab === "reports" && (
              <div>
                {/* Bills tab content */}
                <AccTransactionReports />
              </div>
            )}

            {activeTab === "createemail" && (
              <div>
                {/* Work Mail Account Display */}
                <WorkMailAccountDisplay />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsView;
