import axios from 'axios';
import { getApiBaseUrl } from '../../config/constants';
import api from '../../services/api';

export interface FormData {
  id ?: number;
  businessName: string;
  legalStructure: string;
  industryType: string;
  businessModel: string;
  accountingSoftware: string;
  chartOfAccounts: boolean;
  taxIdNumber: string;
  address: string;
  user?: number | null;
}

const createBusinessDetails = async (formData: FormData) => {
  try {
    formData.user = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId') as string) : null;
    const response = await axios.post(`${getApiBaseUrl()}/business-details/`, formData);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const updateBusinessDetails = async (businessId: string, formData: FormData) => {
  try {
    const response = await axios.put(`${getApiBaseUrl()}/business-details/${businessId}/`, formData);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getBusinessDetailsByUserId = async (userId: string) => {
  try {
    const response = await axios.get(`${getApiBaseUrl()}/business-detail/user/${userId}/`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error:', error);
    return { success: false, message: 'Failed to fetch business details' };
  }
};


export const getUserProfile = async (userId: string) => {
  try {
    const response = await api.get(`${getApiBaseUrl()}/user-profile/${userId}/`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return { success: false, message: 'Failed to fetch user profile' };
  }
};

export const updateUserProfile = async (userId: string, data: any) => {
  try {
    const response = await api.put(`${getApiBaseUrl()}/update-user-profile/${userId}/`, data);
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error updating user profile:', error);
    return { success: false, message: 'Failed to update user profile' };
  }
};

export const deleteAccount = async (userId: string) => {
  try {
    const response = await api.delete(`${getApiBaseUrl()}/delete-user-data/${userId}/`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error deleting user profile:', error);
    return { success: false, message: 'Failed to delete user profile' };
  }
};

export default createBusinessDetails;