import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ProfileProps } from './types';
import { FiCheckCircle, FiX } from 'react-icons/fi';
import { getApiBaseUrl } from '../../../../config/constants';

const steps = [
  {
    id: 3,
    title: 'Start Your Complimentary Trial',
    description: 'Sign up with your payment details to enjoy a one-month free trial period.',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 21v-9m3-4H7.5a2.5 2.5 0 1 1 0-5c1.5 0 2.875 1.25 3.875 2.5M14 21v-9m-9 0h14v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-8ZM4 8h16a1 1 0 0 1 1 1v3H3V9a1 1 0 0 1 1-1Zm12.155-5c-3 0-5.5 5-5.5 5h5.5a2.5 2.5 0 0 0 0-5Z" />
      </svg>
    ),
    link: '/dashboard/billing',
  },
  // {
  //   id: 6,
  //   title: 'Connect Bank account',
  //   description: 'Connect your bank account to get real-time financial insights with Plaid.',
  //   icon: (
  //     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
  //     </svg>
  //   ),
  //   link: '/dashboard/billing',
  // },
];

interface PopupProps {
  closePopup: () => void;
}

const Popup: React.FC<PopupProps> = ({ closePopup }) => {
  const [profileData, setProfileData] = useState<ProfileProps>();
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  console.log(completedSteps.length);
  

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    const completedStepsList = [1,2];
    if (profileData?.trial_activated) completedStepsList.push(3);
    if (profileData?.business_details_added) completedStepsList.push(4);
    if (profileData?.financial_summary_reviewed) completedStepsList.push(5);
    if (profileData?.accountant_messages_checked) completedStepsList.push(6);
    setCompletedSteps(completedStepsList);
  }, [profileData]);

  const fetchInfo = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(`${getApiBaseUrl()}/user-progress/?user=${userId}`);    
      setProfileData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStepClick = (stepLink: string) => {
    if ( !stepLink ) return;
    window.open(stepLink, '_blank');
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto my-auto ">
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Onboarding Process</h2>
        <FiX className="w-6 h-6 text-black cursor-pointer" onClick={closePopup} />
      </div>
      {/* <div className="mb-4">
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-primary rounded-full transition-all duration-500 ease-out"
            style={{ width: `${(completedSteps.length / steps.length) * 100}%` }}
          ></div>
        </div>
        <p className="text-right text-sm text-gray-600 mt-1">{Math.round((completedSteps.length / steps.length) * 100)}% Complete</p>
      </div> */}
      <div className="space-y-4">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`flex items-start p-4 rounded-lg transition-all duration-200 ease-in-out ${
              completedSteps.includes(step.id) ? 'bg-primary text-white' : 'bg-gray-100 hover:bg-gray-200'
            }`}
            onClick={completedSteps.includes(step.id) ? undefined : () => handleStepClick(step.link)}
          >
            <div className={`rounded-full p-2 mr-4 ${completedSteps.includes(step.id) ? 'bg-white' : 'bg-primary'}`}>
              {completedSteps.includes(step.id) ? (
                <FiCheckCircle className="w-6 h-6 text-primary" />
              ) : (
                React.cloneElement(step.icon, {
                  className: `w-6 h-6 ${completedSteps.includes(step.id) ? 'text-primary' : 'text-white'}`,
                })
              )}
            </div>
            <div>
              <h3 className={`font-semibold ${completedSteps.includes(step.id) ? 'text-white' : 'text-gray-800'}`}>
                {step.title}
              </h3>
              <p className={`text-sm ${completedSteps.includes(step.id) ? 'text-white' : 'text-gray-600'}`}>
                {step.description}
              </p>
              <p className={`text-xs mt-1 ${completedSteps.includes(step.id) ? 'text-white' : 'text-gray-500'}`}>
                {completedSteps.includes(step.id) ? 'Completed' : 'Pending'}
              </p>
            </div>
          </div>
        ))}
        <div className="flex w-full justify-end items-end">
          <button className="bg-gray-200 text-black border py-2 px-10 rounded-lg" type="button" onClick={closePopup}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
