import React from 'react';

const MessageCard = ({ message, messageType, link="", icon } : {
  message: string;
  messageType: 'normal' | 'information' | 'warning' | string;
  link?: string;
  icon ?: React.ReactNode;

}) => {
  let cardStyle, iconStyle;
  cardStyle = 'bg-white text-gray-700 shadow-md border-l-4 border-primary border';
      iconStyle = 'text-gray-700';

  

  return (
    <a href={link}>
    <div className={`p-4 my-4 rounded-md ${cardStyle}`}>
      <div className="flex items-center">
        <div className={`mr-4 ${iconStyle}`}>
          {
            icon
          }
          {messageType === 'information' || messageType === 'normal' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          )}
          {messageType === 'warning' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          )}
        </div>
        <div className='font-bold'>{message}</div>
      </div>
    </div>
    </a>
  );
};

export default MessageCard;