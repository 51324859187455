import axios from 'axios';
import { getApiBaseUrl } from '../../config/constants';
import api from '../../services/api';

// Define the fake API endpoint
const API_URL = 'https://example.com/api/signup';

interface FormData {
  name: string;
  email: string;
  password: string;
}
// Define the fake API call function
async function fakeAPICall(formData: FormData): Promise<{ success: boolean, token: string, user: number }> {
  try {
    // Simulate the API call by using a delay
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Return the fake response
    return { success: true, token: "akskakska", user: 12 };
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error('API call failed:', error);
    throw error;
  }
}

// above is the api call for signup and signin
// Sign up function
async function signUp(formData: FormData): Promise<{ success: boolean, token: string, user: number,  response ?: string }> {
  try {
    const response = await axios.post(`${getApiBaseUrl()}/signup/`, formData);
    return response.data;
  } catch (error) {
    console.error('Sign up failed:', error);
    throw error;
  }
}

// Sign in function
async function signIn(formData: { email: string, password: string }): Promise<{ success: boolean, token: string, user: number, response ?: string }> {
  try {
    const response = await axios.post(`${getApiBaseUrl()}/signin/`, formData);
    return response.data;
  } catch (error) {
    console.error('Sign in failed:', error);

    throw error;
  }
}

interface ForgotPasswordResponse {
  success: boolean;
  response?: string;
}

interface ForgotPasswordData {
  email: string;
}

const forgotPassword = async (data: ForgotPasswordData): Promise<ForgotPasswordResponse> => {
  try {
    const response = await axios.post(`${getApiBaseUrl()}/forgot-password/`, data);
    return response.data;
  } catch (error) {
    console.error("Forgot password API error:", error);
    return {
      success: false,
      response: "An error occurred",
    };
  }
};

export { fakeAPICall, signUp, signIn , forgotPassword };