import {
  Avatar,
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { getApiBaseUrl } from "../../../config/constants";
import uploadFileToS3 from "../../../financial-management/services/fileUploader";

type MessageType = {
  id: number;
  content: string;
  attachment: string | null;
  link: string;
  timestamp: string;
  chat_room: number;
  sender: number;
};

type ChatRoomType = {
  id: number;
  created_at: string;
  user: number;
  chat_type: string;
  accountant: number | null;
  success_manager: number | null;
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  return `${formattedDate} ${formattedTime}`;
};

const ChatWindowSuccessManager = () => {
  const [messages, setMessages] = useState<MessageType[] | null>([]);
  const [chatRoom, setChatRoom] = useState<ChatRoomType | null>(null);
  const [currentUserID, setCurrentUserID] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const accid: string = localStorage.getItem("accId") || "1";
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userID = parseInt(searchParams.get("userid") || "0");
    setCurrentUserID(1);
    fetchChatRoom(userID);
  }, []);

  useEffect(() => {
    if (chatRoom) {
      const interval = setInterval(fetchMessages, 3000);
      return () => clearInterval(interval);
    }
  }, [chatRoom]);

  const fetchChatRoom = async (userID: number) => {
    try {
      const response = await fetch(
        `${getApiBaseUrl()}/chat-room/${userID}/SUCCESS_MANAGER/`
      );
      const data = await response.json();
      console.log(data);
      setChatRoom(data.chat_room_id);
    } catch (error) {
      console.error("Error fetching chat room:", error);
    }
  };

  const fetchMessages = async () => {
    if (chatRoom) {
      try {
        const response = await fetch(
          `${getApiBaseUrl()}/messages/?chatroom=${chatRoom}`
        );
        const data = await response.json();
        const sortedMessages = data.sort(
          (a: MessageType, b: MessageType) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  const handleSend = async (value: string) => {
    if (chatRoom) {
      try {
        const response = await fetch(`${getApiBaseUrl()}/messages/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content: value,
            attachment: null,
            link: "",
            chat_room: chatRoom,
            sender: accid, // admin accounts
          }),
        });

        if (response.ok) {
          fetchMessages();
        } else {
          console.error("Error sending message");
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleAttach = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*, video/*, audio/*";
    fileInput.addEventListener("change", handleFileSelect);
    fileInput.click();
  };

  const handleFileSelect = async (event: Event) => {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files?.[0];
    if (file) {
      setIsLoading(true);

      const bucketName = "outss-assets";
      const key = `attachments/${Date.now()}-${file.name}`;

      try {
        const fileUrl = await uploadFileToS3(file, bucketName, key);

        if (chatRoom) {
          const response = await fetch(`${getApiBaseUrl()}/messages/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              content: "",
              attachment: fileUrl,
              link: "",
              chat_room: chatRoom,
              sender: parseInt(accid) ,
            }),
          });

          if (response.ok) {
            fetchMessages();
          } else {
            console.error("Error sending attachment message");
            alert("Failed to send attachment message. Please try again.");
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openImagePopup = (imageUrl: string | null) => {
    setSelectedImage(imageUrl);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="w-[90%] h-[80%] flex-col items-center bg-white pl-2 ml-4 mt-2 lg:mt-2">
      <div className="h-[80%]">
        {chatRoom && (
          <div className="flex flex-col lg:flex-row space-x-4 items-center mb-4 justify-between">
            <h4 className="text-gray-500 mb-4">
              Ask questions, get help, and stay in touch with your accountant
            </h4>
            <button className="flex flex-row bg-gray-200 p-2 rounded-lg space-x-3 items-center">
              <FaPhoneAlt className="text-xl text-primary" />
              <p className="text-md">Call us</p>
            </button>
          </div>
        )}

        <MainContainer
          style={{
            borderRadius: 10,
            width: "100%",
            maxWidth: "100vw",
            height: isMobile ? "65vh" : "75vh",
            maxHeight: "80vh",
          }}
        >
          <ChatContainer color="purple">
            <MessageList
              color="green"
              style={{
                height: isMobile ? "calc(65vh - 150px)" : "calc(80vh - 150px)",
                overflowY: "auto",
                width: isMobile ? "100%" : "100%",
              }}
              scrollBehavior="smooth"
            >
              {messages?.map((msg) => (
                <Message
                  key={msg.id}
                  onClick={() => {
                    if (msg.attachment) {
                      openImagePopup(msg.attachment);
                    }
                  }}
                  model={{
                    message: msg.content,
                    sentTime: msg.timestamp,
                    sender: msg.sender === parseInt(accid)  ? "You" : "User",
                    direction: msg.sender === parseInt(accid)  ? "outgoing" : "incoming",
                    position: "last",
                  }}
                >
                  {msg.sender !== 3 && (
                    <Avatar
                      name="User"
                      src="https://outss-assets.s3.me-central-1.amazonaws.com/pdf/icons8-agent-100.png"
                    />
                  )}

                  {msg?.attachment && (
                    <Message.ImageContent
                      src={msg.attachment}
                      alt="Attachment"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      onClick={() => openImagePopup(msg?.attachment)}
                      width={200}
                      height={200}
                    />
                  )}

                  <Message.Footer sentTime={msg.timestamp}>
                    <p>{formatTimestamp(msg.timestamp)}</p>
                  </Message.Footer>
                </Message>
              ))}
            </MessageList>
            <MessageInput
              placeholder="Type message here"
              onSend={handleSend}
              style={{ height: "100px" }}
              attachButton={true}
              onAttachClick={handleAttach}
            />
          </ChatContainer>
        </MainContainer>
        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
        )}
      </div>
      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeImagePopup}
        >
          <div className="relative w-[90%] h-[90%] items-center flex ">
            <img
              src={selectedImage}
              alt="Full-size attachment"
              className="w-[90%] h-[90%] object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindowSuccessManager;
