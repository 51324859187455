import { APIResponse } from "../../../services/summary";

interface Expense {
  name: string;
  amount: string;
}

interface ExpenseWithPercentage extends Expense {
  percentage: string;
}

const ExpenseItem = ({
  category,
  amount,
  percentage,
}: {
  category: string;
  amount: string;
  percentage: string;
}) => (
  <div className="flex flex-col py-2">
    <div className="text-sm mb-1">{category}</div>
    <div className="flex items-center mb-1">
      <div className="text-sm font-bold mr-2">{`$${amount}`}</div>
      <div className="text-sm">{percentage}</div>
    </div>
    <div className="w-full h-3 bg-gray-200">
      <div
        className="bg-primary h-3"
        style={{ width: `min(${percentage}, 100%)` }}
      ></div>
    </div>
  </div>
);

const TopExpensesCard = ({ data }: { data: APIResponse }) => {
  const totalAmount = data?.response?.operating_expenses?.reduce(
    (total: number, expense: Expense) => total + parseFloat(expense.amount.replace(",", "")),
    0
  );

  const expensesWithPercentage: ExpenseWithPercentage[] | undefined = data?.response?.operating_expenses?.map(
    (expense: Expense) => {
      const amount = parseFloat(expense.amount.replace(",", ""));
      const percentage =
        totalAmount !== 0 ? ((amount / totalAmount) * 100).toFixed(0) : "0";
      return {
        ...expense,
        percentage: `${percentage}%`,
      };
    }
  );

  if (
    data?.response?.operating_expenses?.length === 0 ||
    data?.response?.operating_expenses === undefined ||
    data?.response?.operating_expenses === null
  ) {
    return <></>;
  }

  return (
    <div className="max-w-full bg-white border border-gray-200 shadow-sm rounded-lg p-6 mx-5 md:mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Top Expenses</h2>
        <button className="text-indigo-600 hover:text-indigo-800 text-sm"></button>
      </div>
      <div>
        {expensesWithPercentage?.map((expense: ExpenseWithPercentage, index: number) => (
          <ExpenseItem
            key={index}
            category={expense.name}
            amount={expense.amount}
            percentage={expense.percentage}
          />
        ))}
      </div>
    </div>
  );
};

export default TopExpensesCard;