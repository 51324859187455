import React, { useState } from "react";
import { FaCheck, FaClock, FaDotCircle, FaMinus } from "react-icons/fa";
import dummyData from "../../../constants/dummyData";
import uploadFileToS3 from "../../services/fileUploader";
import IncomeStatement from "../../components/bookkeeping/IncomeStatements";

const Statements: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>(
    null
  );
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      // TODO: Implement file upload logic to the server
      console.log("Uploading file:", selectedFile);
      const randomFileName: string =
        Math.random().toString(36).substring(2, 5) +
        Math.random().toString(36).substring(2, 5);
      const fileName = randomFileName + selectedFile.name;
      console.log("File name:", fileName);
      uploadFileToS3(selectedFile, "outss-assets/pdf-files", selectedFile.name)
        .then((fileUrl) => {
          console.log("File uploaded successfully:", fileUrl);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
    alert("File uploaded successfully");
  };
  return <IncomeStatement />;

  return (
    <div className="flex flex-col items-center justify-start h-screen my-12 mx-4">
      <h1 className="text-2xl font-bold mb-4">Upload Bank Statement</h1>

      <table className="border-separate border-spacing-2 border border-slate-500">
        <thead>
          <tr>
            {dummyData.map((data, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2">
                {data.month ===
                new Date().toLocaleString("default", { month: "long" }) ? (
                  <p className="flex items-center justify-center">
                    {data.month}
                    <FaDotCircle color="green" />
                  </p>
                ) : (
                  <p className="flex items-center justify-center">
                    {data.month}
                  </p>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {dummyData.map((data, index) => (
              <td key={index} className="border border-gray-300 px-4 py-2">
                {data.status === "submitted" ? (
                  <FaCheck color="green" />
                ) : data.status === "pending" ? (
                  <FaClock color="#C9A74A" />
                ) : (
                  <FaMinus color="gray" />
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <div className="flex items-center justify-center mt-8">
        <select className="border border-gray-300 px-4 py-2 mb-4">
          <option value="">Select a month</option>
          {dummyData.map((data, index) => (
            <option key={index} value={data.month}>
              {data.month}
            </option>
          ))}
        </select>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
          className="mb-4"
        />
        <button
          onClick={handleUpload}
          disabled={!selectedFile}
          className={`px-4 py-2 bg-blue-500 text-white rounded ${
            !selectedFile && "opacity-50 cursor-not-allowed"
          }`}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default Statements;
