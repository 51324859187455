import { useQuery } from 'react-query';
import { createContext, useContext } from 'react';
import { getApiBaseUrl } from '../../config/constants';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface ExpenseIncomeItem {
  name: string;
  amount: string;
}

export interface SummaryData {
  total_income: string;
  total_expenses: string;
  net_income: string;
  operating_expenses: ExpenseIncomeItem[];
}

export interface APIResponse {
  success: boolean;
  response: SummaryData;
}

const SummaryContext = createContext<APIResponse | undefined>(undefined);

const fetchSummaryData = async (dateRange: DateRange): Promise<APIResponse> => {
  // date format 2024-04-01
  const fromDate = dateRange.startDate.toISOString().split('T')[0];
  const toDate = dateRange.endDate.toISOString().split('T')[0];
  const userId = localStorage.getItem('userId') || '1';
  const response = await fetch(`${getApiBaseUrl()}/profit-and-loss/${userId}/?start_date=${fromDate}&end_date=${toDate}`);
  return response.json();
};

export const useSummaryData = (dateRange: DateRange) => {
  return useQuery(['summaryData', dateRange], () => fetchSummaryData(dateRange), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24
  });
};

interface SummaryProviderProps {
  children: React.ReactNode;
}

export const SummaryProvider: React.FC<SummaryProviderProps> = ({ children }) => {
  const startDate = new Date();
  startDate.setDate(1); // set the day to the first day of the month
  startDate.setMonth(startDate.getMonth() - 3);
  const formattedStartDate = `${startDate.getFullYear()}-${('0' + (startDate.getMonth() + 1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
  const endDate = new Date();
  const formattedEndDate = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;
  const dateRange: DateRange = {
    startDate: new Date(formattedStartDate),
    endDate: new Date(formattedEndDate),
  };
  const { data, isLoading, error } = useSummaryData(dateRange);
  if (error) {
    console.error(error);
  }
  
  return ( <SummaryContext.Provider value={data}>
      {isLoading ? <div>Loading...</div> : children}
    </SummaryContext.Provider>
  );
};

export const useSummaryContext = () => {
  const context = useContext(SummaryContext);
  if (context === undefined) {
    throw new Error('useSummaryContext must be used within a SummaryProvider');
  }
  return context;
};