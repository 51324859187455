import React from 'react';
import { FiBellOff } from 'react-icons/fi';
import logo from "../../assets/logo.png";

const Unsubscribe = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <img src={logo} alt="logo" className="w-24 mx-auto" />
        <div className="mb-6"> </div>
        <div className="mb-6">
          <FiBellOff className="text-red-500 w-16 h-16 mx-auto" />
        </div>
        <h1 className="text-2xl font-bold mb-2 text-gray-800">Unsubscribed</h1>
        <p className="text-gray-600 mb-4">
          You have successfully unsubscribed from email alerts.
        </p>
        {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300">
          Manage Preferences
        </button> */}
      </div>
    </div>
  );
};

export default Unsubscribe;