import React, { useEffect } from "react";
import Routes from "./Routes";
import { SummaryProvider } from "./financial-management/services/summary"
import { QueryClient, QueryClientProvider } from 'react-query';
import { MantineProvider } from "@mantine/core";
import '@mantine/core/styles.css';
import mixpanel from "mixpanel-browser";

mixpanel.init("62d014cbec3bff8863c174104af0bcb9");

const queryClient = new QueryClient();


function App(): JSX.Element {

  return (
    <QueryClientProvider client={queryClient}>
        <MantineProvider>
          <div className="App">
          <SummaryProvider >
            <Routes />
            </SummaryProvider>
          </div>
          </MantineProvider>
        
    </QueryClientProvider>
  );
}

export default App;
