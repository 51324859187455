import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApiBaseUrl } from "../../../config/constants";
import { useMediaQuery } from "react-responsive";

interface User {
  id: number;
  name: string;
  email: string;
  business_name:string;
  business_id:number;
}

const UsersTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  useEffect(() => {
    const fetchUsers = async () => {
      const userId = localStorage.getItem("accId");
      try {
        const response = await axios.get<User[]>(`${getApiBaseUrl()}/accountant-users-list/${userId}/`,{
          headers: {
            'Authorization': `token ${localStorage.getItem('token')}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className={`px-3 bg-white min-h-screen ${isMobile? 'pr-0' : 'pr-10'}`}>
      <div className="container mx-auto">
        <h1 className={`text-3xl font-bold mb-6 ${isMobile && 'text-center' }`}>My Users</h1>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {users.length !== 0 ?             
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Business Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.business_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <Link to={`/clients/${user.id}`} className="text-indigo-600 hover:text-indigo-900">
                        View Details
                      </Link> */}

                  <Link
                    to={`/accountants/viewuser/${user.id}?userid=${user.id}&businessid=${user.business_id}`}
                    className="text-blue-500 hover:underline"
                  >
                    View Details
                  </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> : <p className="text-center p-4">No users found</p>}
        </div>
      </div>
    </div>
  );
};

export default UsersTable;