import React from 'react'
import { FaDownload } from 'react-icons/fa'
import { FileData } from '../../financial-management/pages/MyBooks';
  
interface FileTableProps {
    files: FileData[] | undefined;
}
  
  export const ReviewTable: React.FC<FileTableProps> = ({ files }) => {

    const humenReadableTime = (dateStr: string | null) => {
        if (!dateStr) return "N/A";
        const date = new Date(dateStr);
        
        // Convert to local time
        const formattedDate = date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
        });
        
        // Adjust the hour to 12 PM
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        let minute = minutes.toString().padStart(2, "0"); // add prefix '0' if minutes less than 10
        let hour = hours.toString().padStart(2, "0"); // add prefix '0' if hours less than 10
        const formattedTime = `${hour}:${minute} ${ampm}`;
        
        return `${formattedDate} ${date.getFullYear()} | ${formattedTime}`;
    };
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
        <table className="w-full">
            <thead>
                <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left">File Name</th>
                <th className="px-6 py-3 text-left">Type</th>
                <th className="px-6 py-3 text-left">Uploaded At</th>
                <th className="px-6 py-3 text-left">Status</th>
                <th className="px-6 py-3 text-left flex justify-center">Action</th>
                </tr>
            </thead>
            <tbody>
            {files?.map((file) => (
                <tr key={file.id} className={file.id % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                    <td className="px-6 py-4">{file.link.split('/')[4]}</td>
                    <td className="px-6 py-4">{file.link.split('.').pop()}</td>
                    <td className="px-6 py-4"><p className="text-sm">{humenReadableTime(file.created_at)}</p></td>
                    <td className="px-6 py-4">
                    <span className={`px-2 py-1 rounded ${
                        file.status === 'pending' ? 'bg-yellow-200 text-yellow-800' :
                        file.status === 'completed' ? 'bg-green-200 text-green-800' :
                        'bg-red-200 text-red-800 text-sm'
                    }`}>
                        {file.status}
                    </span>
                    </td>
                    <td className="px-6 py-4 flex justify-center">
                    <button onClick={() => window.open(file.link)} className="text-green-500 hover:text-green-700">
                        <FaDownload className="inline-block" />
                    </button>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
  )
}
