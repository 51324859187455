import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrialActivation from '../Payments/TrialActivation';
import InvoiceView from '../Invoicing/InvoiceView';
import { getApiBaseUrl } from '../../../config/constants';



const Billings: React.FC = () => {
  const [billingEnabled, setBillingEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userid = localStorage.getItem("userId")
    setIsLoading(true);
    axios.get(`${getApiBaseUrl()}/customer-billing/${userid}/`)
      .then(response => {
        setIsLoading(false);
        setBillingEnabled(response.data.success);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching customer billing data:', error);
        setBillingEnabled(false); // Assume failure on error
      });
      setIsLoading(false);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="container mx-auto p-4">
      {billingEnabled === null ? (
        <div className="text-center text-gray-500">Loading...</div> // Loading state
      ) : billingEnabled ? (
        <InvoiceView /> 
      ) : (
        
        <TrialActivation /> 
      )}
    </div>
  );
}

export default Billings;
