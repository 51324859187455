import React, { useEffect, useRef, useState } from "react";
import {
  FaCheck,
  FaClock,
  FaDownload,
  FaFileArchive,
  FaFileExcel,
  FaTimes,
  FaTrash,
  FaUpload,
  FaFile,
  FaFilePdf,
  FaFileWord,
  FaFileImage
} from "react-icons/fa";
import uploadFileToS3 from "../../../financial-management/services/fileUploader";
import axios from "axios";
import { getHumanReadableDate } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getApiBaseUrl } from "../../../config/constants";
import PlaidIntegrationConcise from '../../../components/PlaidIntegrationConcise';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from "../../../services/api";
import mixpanel from "mixpanel-browser";
import { GoSortAsc, GoSortDesc } from "react-icons/go";
import { showNotification } from "@mantine/notifications";
import { ReviewTable } from "../../../components/ReviewTable";

export interface FileData {
  id: number;
  link: string;
  status: string;
  created_at: string;
  is_filed: boolean;
  user: number;
}

interface BankConnection {
  id: number;
  institution_name: string;
  account_name: string;
  account_type: string;
  account_subtype: string;
}

interface Transaction {
  id: string;
  date: string;
  description: string;
  amount: number;
  category: string[];
}
interface TransactionResponse {
  transactions: Transaction[];
  total_transactions: number;
  total_pages: number;
  current_page: number;
  has_next: boolean;
  has_previous: boolean;
}

const getFileIcon = (link?: string) => {
  if (!link || !link.includes(".") || link === undefined) {
    return <FaFile className="text-gray-500" />;
  }
  const extension = link?.split(".").pop()?.toLowerCase();

  switch (extension) {
    case "pdf":
      return <FaFilePdf className="text-primary" size={30} />;
    case "docx":
      return <FaFileWord className="text-blue-500" size={30} />;
    case "jpg":
    case "jpeg":
      return <FaFileImage className="text-primary" size={30} />;
    case "xlsx":
      return <FaFileExcel className="text-green-500" size={30} />;
    case "zip":
      return <FaFileArchive className="text-yellow-500" size={30} />;
    default:
      return <FaFile className="text-gray-500" />;
  }
};

const MyBooks: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [fetchedFiles, setFetchedFiles] = useState<FileData[]>([]);
  const [activeTab, setActiveTab] = useState<"upload" | "review">("upload");
  const [isDragging, setIsDragging] = useState(false);
  const [showPlaidPopup, setShowPlaidPopup] = useState(false);
  const [activeMainTab, setActiveMainTab] = useState<"books" | "bankAccounts">("books");
  const [bankConnections, setBankConnections] = useState<BankConnection[]>([]);
  const [selectedConnection, setSelectedConnection] = useState<BankConnection | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [sortOrder, setSortOrder] = useState('asc');

  const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [perPage] = useState<number>(20);

  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
  const ALLOWED_FILE_TYPES = [
    "pdf",
    "docx",
    "xlsx",
    "jpg",
    "jpeg",
    "zip",
    "csv",
    "png",
  ];

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validFiles: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > MAX_FILE_SIZE) {
          alert(`File ${file.name} exceeds the maximum limit of 20 MB`);
          continue;
        }
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!ALLOWED_FILE_TYPES.includes(fileExtension || "")) {
          alert(`Invalid file type for ${file.name}. Only PDF, DOCX, XLSX, CSV, JPG, JPEG, PNG and ZIP files are allowed.`);
          continue;
        }
        validFiles.push(file);
      }
      setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
      uploadFiles(validFiles);
    }
  };

  const uploadFiles = async (files: File[]) => {
    setUploading(true);
    const uploadPromises = files.map(file => uploadFileToS3(file));
    try {
      const urls = await Promise.all(uploadPromises);
      setFileUrls(prevUrls => [...prevUrls, ...urls]);
      setUploading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploading(false);
      alert("Failed to upload one or more files. Please try again.");
    }
  };

  const uploadFileToS3 = async (file: File) => {  
    try {
      const arrayBuffer = await file.arrayBuffer();
      
      const base64 = btoa(
        new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
  
      const response = await axios.post(
        `https://8n41u6mb2f.execute-api.us-east-1.amazonaws.com/default/s3outssupload?fileName=${file.name}`,
        base64,
        {
          headers: {
            'Content-Type': file.type,
          },
        }
      );
      console.log('File uploaded:', response.data);
      return response.data.url;
    } catch (error) {
      console.error('Error uploading file:', error);
      showNotification({
        title: "Error",
        message: "Failed to upload file. Please try again.",
        color: "red",
      });
    }
  };

  const oldUpload = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `https://8n41u6mb2f.execute-api.us-east-1.amazonaws.com/default/s3outssupload?fileName=${file.name}`,
      formData,
      {
        headers: {
          "Content-Type": file.type,
        },
        maxBodyLength: Infinity,
      }
    );
    return response.data.url;
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    const validFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > MAX_FILE_SIZE) {
        alert(`File ${file.name} exceeds the maximum limit of 20 MB`);
        continue;
      }
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!ALLOWED_FILE_TYPES.includes(fileExtension || "")) {
        alert(`Invalid file type for ${file.name}. Only PDF, DOCX, XLSX, JPG, JPEG, PNG, CSV and ZIP files are allowed.`);
        continue;
      }
      validFiles.push(file);
    }
    setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    uploadFiles(validFiles);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setIsDragging(false);
    }
  };

  const saveFilesToLocalStorage = (fileUrl: string[]) => {
    setUploadedFiles(fileUrl);
    localStorage.setItem("uploadedFiles", JSON.stringify(fileUrl));
  };

  useEffect(() => {
    saveFilesToLocalStorage(fileUrls);
  }, [fileUrls]);

  const removeFile = (fileUrl: string) => {
    console.log('remove fileUrl', fileUrl);
    const updatedFiles = uploadedFiles.filter((url) => url !== fileUrl);
    setUploadedFiles(updatedFiles);
    localStorage.setItem("uploadedFiles", JSON.stringify(updatedFiles));
  };

  const fetchFilesFromServer = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const url = `${getApiBaseUrl()}/user/bank-statement/`;

      const response = await api.get(url);
      console.log('success response.data', response.data);
      setFetchedFiles(response.data.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const deleteFile = async (fileId: number) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const url = `${getApiBaseUrl()}/bank-statement/${fileId}/`;
              await axios.delete(url);
              fetchFilesFromServer();
              alert("File deleted successfully");
            } catch (error) {
              console.error("Error deleting file:", error);
              alert("Failed to delete file");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const sendFilesToServer = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const uploadPromises = fileUrls.map(url => {
      const formData = {
        link: url,
        user: userId,
      };
      return fetch(`${getApiBaseUrl()}/bank-statement/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `token ${token}`
        },
        body: JSON.stringify(formData),
      });
    });

    try {
      await Promise.all(uploadPromises);
      fetchFilesFromServer();
      setSelectedFiles([]);
      setFileUrls([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const fetchBankConnections = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`${getApiBaseUrl()}/plaid/bank-connections/${userId}/`);
      setBankConnections(response.data);
      if (response.data.length === 0) {
        setShowPlaidPopup(true);
      }
    } catch (error) {
      console.error("Error fetching bank connections:", error);
    }
  };

  const fetchTransactions = async (connectionId: number, page: number) => {
    try {
      const userId = localStorage.getItem("userId");
      const queryParams = new URLSearchParams({
        start_date: startDate?.toISOString().split('T')[0] || '',
        end_date: endDate?.toISOString().split('T')[0] || '',
        page: page.toString(),
        per_page: perPage.toString(),
      });
      const response = await axios.get(`${getApiBaseUrl()}/plaid/transactions/${userId}/${connectionId}/?${queryParams}`);
      const data: TransactionResponse = response.data;
      setTransactions(data.transactions);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  const handleConnectionSelect = (connection: BankConnection) => {
    setSelectedConnection(connection);
    setCurrentPage(1);
    fetchTransactions(connection.id, 1);
  };

  const handlePageChange = (newPage: number) => {
    if (selectedConnection) {
      setCurrentPage(newPage);
      fetchTransactions(selectedConnection.id, newPage);
    }
  };

  const handleDateChange = () => {
    if (selectedConnection) {
      setCurrentPage(1);
      fetchTransactions(selectedConnection.id, 1);
    }
  };

  useEffect(() => {
    const storedFiles = localStorage.getItem("uploadedFiles");
    if (storedFiles) {
      setUploadedFiles(JSON.parse(storedFiles));
    }
    fetchFilesFromServer();
  }, []);

  // useEffect(() => {
  //   if (activeMainTab === "bankAccounts") {
  //     fetchBankConnections();
  //   }
  // }, [activeMainTab]);

  const handleOpenModule = () => {
    setShowPlaidPopup(true);
    mixpanel.track("Securely Connect Bank Account Button Clicked in My Book",{
      email: localStorage.getItem("mailId"),
      platform: platform,
    });
  }

  // Function to detect the platform
  function detectPlatform() {
    var userAgent = navigator.userAgent || navigator.vendor ;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
        return "mobile";
    }
    return "web";
  }

  // Store the platform
  var platform = detectPlatform();

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedFiles = [...fetchedFiles].sort((a, b) => {
    const comparison = a.created_at.localeCompare(b.created_at);
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  return (
    <div>
      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex md:hidden flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14">
          {'My Books'}
        </h1>
        <div className="flex items-center ml-0 lg:ml-auto"></div>
      </div>
      <div className="flex flex-col bg-white mx-6 md:mx-0 md:ml-4 pt-24 pl-0 md:pt-0 md:pl-2 lg:mt-6">
        <h3 className="text-2xl font-semibold hidden md:block">My Books</h3>
        <div className="flex mb-4 mt-4">
          <button
            className={`px-4 py-2 rounded-md ${
              activeMainTab === "books" ? "bg-primary text-white" : "bg-gray-200 text-gray-500"
            }`}
            onClick={() => setActiveMainTab("books")}
          >
            My Books
          </button>
          <button
            className={`px-4 py-2 rounded-md ml-4 ${
              activeMainTab === "bankAccounts" ? "bg-primary text-white" : "bg-gray-200 text-gray-500"
            }`}
            onClick={() => setActiveMainTab("bankAccounts")}
          >
            My Bank Accounts
          </button>
        </div>

        {activeMainTab === "books" && (
          <div className="bg-white rounded-lg shadow-sm p-6 w-full max-w-3xl border mt-6">
            <div className="flex mb-4">
              <button
                className={`px-4 py-2 rounded-md ${
                  activeTab === "upload"
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
                onClick={() => setActiveTab("upload")}
              >
                Submit Documents
              </button>
              <button
                className={`px-4 py-2 rounded-md ml-4 ${
                  activeTab === "review"
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
                onClick={() => setActiveTab("review")}
              >
                Documents in Review
              </button>
            </div>

            {activeTab === "upload" && (
              <>
                <div
                  className={`border-2 border-dashed rounded-md p-4 mb-6 ${
                    isDragging ? "border-primary bg-blue-50" : "border-gray-300"
                  }`}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <p className="text-gray-500 mb-4">
                    Support: Only PDF, DOCX, XLSX, CSV, JPG, JPEG, PNG and ZIP files are allowed.
                  </p>
                  <p className="text-gray-500 mb-4 text-sm">
                    Note: Maximum file size allowed is 20 MB
                  </p>

                  <div className="flex justify-center items-center">
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                    <button
                      className="bg-primary text-white px-4 py-2 rounded-md mb-4 flex flex-row"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <FaUpload className="mr-2" />
                      Upload Documents
                    </button>
                  </div>
                  {selectedFiles.length > 0 ? (
                    <div>
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="flex justify-between items-center mb-2">
                          <p>{file.name}</p>
                          {uploading ? (
                            <div className="flex items-center">
                              <div className="loader"></div>
                              <p className="text-gray-500">Uploading...</p>
                            </div>
                          ) : (
                            fileUrls[index] && (
                              <FaDownload
                                className="text-gray-500 cursor-pointer"
                                onClick={() => window.open(fileUrls[index])}
                              />
                            )
                          )}
                          <FaTrash
                            className="text-gray-500 cursor-pointer"
                            onClick={() => {
                              setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                              setFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
                            }}
                          />
                          {fileUrls[index] && (
                            <div className="flex gap-2 items-center">
                              <FaCheck color="green" />
                              <p className="text-green-500">Uploaded successfully</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500 text-center justify-center">
                      Drop or Select your documents to upload
                    </p>
                  )}

                  <div className="h-10" />
                </div>
                {fileUrls.length > 0 && (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    onClick={sendFilesToServer}
                  >
                    Submit documents to Outss
                  </button>
                )}
              </>
            )}

            {activeTab === "review" && (
              <>
                <div>
                  <h2 className="text-lg font-bold mb-4">
                    Documents In Processing
                  </h2>
                  <div className="mb-4">
                    <button onClick={toggleSortOrder} className="bg-gray-200 px-4 py-2 rounded">
                      Sort by Date {sortOrder === 'asc' ? <GoSortAsc className="inline-block ml-2" /> : <GoSortDesc className="inline-block ml-2" />}
                    </button>
                  </div>
                  {fetchedFiles.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                        <ReviewTable files={sortedFiles} />
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <p className="text-gray-500">
                        There are no documents to display
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

{activeMainTab === "bankAccounts" && (
        <div className="bg-white rounded-lg shadow-sm p-6 w-full max-w-3xl border mt-6">
          <h2 className="text-lg font-bold mb-4">My Bank Accounts</h2>
          {bankConnections.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                {bankConnections.map((connection) => (
                  <div
                    key={connection.id}
                    className={`bg-white rounded-lg shadow-sm p-4 border cursor-pointer ${
                      selectedConnection?.id === connection.id ? 'border-primary' : ''
                    }`}
                    onClick={() => handleConnectionSelect(connection)}
                  >
                    <h3 className="font-semibold">{connection.institution_name}</h3>
                    <p>{connection.account_name}</p>
                    <p className="text-sm text-gray-500">{connection.account_type}</p>
                  </div>
                ))}
              </div>
              {selectedConnection && (
                <div>
                  <h3 className="text-lg font-semibold mt-6 mb-4">
                    Transactions for {selectedConnection.institution_name} - {selectedConnection.account_name}
                  </h3>
                  <div className="mb-4 flex space-x-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Start Date</label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">End Date</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                      />
                    </div>
                    <button
                      onClick={handleDateChange}
                      className="mt-6 px-4 py-2 bg-primary text-white rounded hover:bg-primary"
                    >
                      Apply
                    </button>
                  </div>
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="p-2 text-left">Date</th>
                        <th className="p-2 text-left">Description</th>
                        <th className="p-2 text-right">Amount</th>
                        <th className="p-2 text-left">Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction) => (
                        <tr key={transaction.id} className="border-b">
                          <td className="p-2">{transaction.date}</td>
                          <td className="p-2">{transaction.description}</td>
                          <td className={`p-2 text-right ${transaction.amount < 0 ? 'text-red-500' : 'text-green-500'}`}>
                            ${Math.abs(transaction.amount).toFixed(2)}
                          </td>
                          <td className="p-2">{transaction.category.join(', ')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="mt-4 flex justify-between items-center">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="text-gray-500">Securely link your bank accounts via Plaid's encrypted connection for automatic, 
              secure updates of your statements. No manual uploads needed!</p>
            </div>
          )}
          <button
            className="mt-4 bg-primary text-white px-4 py-2 rounded-md opacity-60"
            onClick={() => handleOpenModule()}
            disabled={true}
          >
            {/* Securely Connect Bank Account */}
            Coming soon...
          </button>
        </div>
      )}
      </div>

      {showPlaidPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowPlaidPopup(false)}
            >
              <FaTimes />
            </button>
            <PlaidIntegrationConcise />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyBooks;