import { useState, useEffect } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import axios from 'axios';
import { getApiBaseUrl } from '../../config/constants';

interface Notification {
  id: number;
  title: string;
  description: string;
  created_at: string;
  user: number;
}

const NotificationList = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const userId = localStorage.getItem('userId') || '1';
      const response = await axios.get<Notification[]>(
        `${getApiBaseUrl()}/notification/?user=${userId}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const removeNotification = async (id: number) => {
    try {
      await axios.delete(`${getApiBaseUrl()}/notification/${id}`);
      setNotifications(notifications.filter((notification) => notification.id !== id));
    } catch (error) {
      console.error('Error removing notification:', error);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {notifications.map((notification) => (
        <div key={notification.id} className="bg-white p-4 rounded shadow">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">{notification.title}</h3>
            <button
              onClick={() => removeNotification(notification.id)}
              className="text-red-400 hover:text-red-700"
            >
              <RiCloseCircleLine size={20} />
            </button>
          </div>
          <p className="mt-2">{notification.description}</p>
        </div>
      ))}
    </div>
  );
};

export default NotificationList;