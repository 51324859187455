import React, { useState, FormEvent } from 'react';
import { TextInput, PasswordInput, Button, Paper, Title, Text, Container, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FiUser, FiLock, FiLogIn } from 'react-icons/fi';

interface LoginResponse {
  token: string;
  error?: string;
}

const AdminLoginPage: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('http://localhost:8000/api/admin/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data: LoginResponse = await response.json();

      if (response.ok && data.token) {
        localStorage.setItem('adminToken', data.token);
        navigate('/admin/dashboard');
      } else {
        setError(data.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <Container size={420} my={40}>
        <Title
          order={2}
          style={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
          className="text-3xl mb-6 text-gray-800"
        >
          Admin Login
        </Title>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" className="bg-white">
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              leftSection={<FiUser className="text-gray-500" />}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mb-4"
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              leftSection={<FiLock className="text-gray-500" />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mb-4"
            />
            {error && <Text color="red" size="sm" mt="sm">{error}</Text>}
            <Group mt="xl">
              <Button type="submit" className="bg-blue-500 hover:bg-blue-600" leftSection={<FiLogIn />}>
                Sign in
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default AdminLoginPage;