import React, { useState, useEffect } from 'react';
import { Table, Pagination } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, Cell, PieChart, Pie } from 'recharts';
import { FaUsers, FaUserTie, FaMoneyBillWave, FaCalendarAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import logo from '../../assets/logo.png';

interface DashboardStats {
  total_users: number;
  total_accountants: number;
  total_businesses: number;
  payments_this_month: number;
  recent_signups: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  user_type: string;
  created_in: string;
}

interface PaymentStat {
  month: string;
  total: number;
  count: number;
}

interface DashboardData {
  dashboard_stats: DashboardStats;
  users: {
    total_count: number;
    page: number;
    page_size: number;
    results: User[];
  };
  payment_statistics: PaymentStat[];
}

const AdminDashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    fetchDashboardData(1);
  }, []);

  const formatMonth = (monthStr: string) => {
    const [year, month] = monthStr.split('-');
    return `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][parseInt(month) - 1]} ${year.slice(2)}`;
  };

  const fetchDashboardData = async (page: number) => {
    const token = localStorage.getItem('adminToken');
    if (!token) return;

    try {
      const response = await fetch(`http://localhost:8000/api/admin/dashboard/?page=${page}&page_size=10`, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch dashboard data');
      }

      const data: DashboardData = await response.json();
      setDashboardData(data);
      setTotalPages(Math.ceil(data.users.total_count / data.users.page_size));
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const prepareChartData = (data: PaymentStat[]): PaymentStat[] => {
    if (data.length === 0) return [];

    // Get the last 6 months
    const today = new Date();
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      return d.toISOString().slice(0, 7); // Format as YYYY-MM
    }).reverse();

    // Map the existing data to the last 6 months
    return last6Months.map(month => {
      const existingData = data.find(d => d.month === month);
      return existingData || { month, total: 0, count: 0 };
    });
  };




  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    fetchDashboardData(newPage);
  };

  if (!dashboardData) return <div>Loading...</div>;

  const chartData = prepareChartData(dashboardData.payment_statistics);

  const userDistributionData = [
    { name: 'Users', value: dashboardData.dashboard_stats.total_users - dashboardData.dashboard_stats.total_accountants },
    { name: 'Accountants', value: dashboardData.dashboard_stats.total_accountants },
  ];

  const COLORS = ['#3b82f6', '#10b981'];

  return (
    <div className="container mx-auto p-4 max-w-5xl">
      {/* logo */}
      <img src={logo} alt="logo" className="w-20 h-20 mx-auto mb-2 object-contain" />

      <h1 className="text-3xl font-bold mb-6 text-gray-800">Admin Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md border-l-4 border-primary">
          <div className="flex items-center justify-between">
            <span className="text-lg font-semibold text-gray-700">Total Users</span>
            <FaUsers className="text-primary text-2xl" />
          </div>
          <p className="text-3xl font-bold text-gray-800 mt-2">{dashboardData.dashboard_stats.total_users}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md border-l-4 border-primary">
          <div className="flex items-center justify-between">
            <span className="text-lg font-semibold text-gray-700">Total Accountants</span>
            <FaUserTie className="text-primary text-2xl" />
          </div>
          <p className="text-3xl font-bold text-gray-800 mt-2">{dashboardData.dashboard_stats.total_accountants}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md border-l-4 border-primary">
          <div className="flex items-center justify-between">
            <span className="text-lg font-semibold text-gray-700">Payments This Month</span>
            <FaMoneyBillWave className="text-primary text-2xl" />
          </div>
          <p className="text-3xl font-bold text-gray-800 mt-2">${dashboardData.dashboard_stats.payments_this_month}</p>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">User List</h2>
        <div className="overflow-x-auto">
          <Table>
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 text-left">Name</th>
                <th className="py-2 px-4 text-left">Email</th>
                <th className="py-2 px-4 text-left">User Type</th>
                <th className="py-2 px-4 text-left">Created On</th>
              </tr>
            </thead>
            <tbody>
              {dashboardData.users.results.map((user) => (
                <tr key={user.id} className="border-b border-gray-200">
                  <td className="py-2 px-4">{user.name}</td>
                  <td className="py-2 px-4 flex items-center">
                    <MdEmail className="mr-2 text-gray-500" />
                    {user.email}
                  </td>
                  <td className="py-2 px-4">{user.user_type}</td>
                  <td className="py-2 px-4 flex items-center">
                    <FaCalendarAlt className="mr-2 text-gray-500" />
                    {new Date(user.created_in).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="mt-4 flex justify-center">
          <Pagination total={totalPages} onChange={handlePageChange} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Payment Statistics (Last 6 Months)</h2>
          <div className="h-80 w-full">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={chartData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#3b82f6" stopOpacity={0.1}/>
                  </linearGradient>
                  <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#10b981" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#10b981" stopOpacity={0.1}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" tickFormatter={formatMonth} axisLine={false} tickLine={false} />
                <Tooltip 
                  labelFormatter={formatMonth}
                  formatter={(value, name) => [value, name === "total" ? "Total Amount ($)" : "Number of Payments"]}
                  contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '8px', border: 'none' }}
                />
                <Legend />
                <Area type="monotone" dataKey="total" stroke="#3b82f6" fillOpacity={1} fill="url(#colorTotal)" name="Total Amount ($)" />
                <Area type="monotone" dataKey="count" stroke="#10b981" fillOpacity={1} fill="url(#colorCount)" name="Number of Payments" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">User Distribution</h2>
          <div className="h-80 w-full flex flex-col items-center justify-center">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={userDistributionData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {userDistributionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name) => [`${name}: ${value}`, '']}
                  contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '8px', border: 'none' }}
                />
              </PieChart>
            </ResponsiveContainer>
            <div className="flex justify-center space-x-4 mt-4">
              {userDistributionData.map((entry, index) => (
                <div key={`legend-${index}`} className="flex items-center">
                  <div className={`w-3 h-3 rounded-full mr-2`} style={{backgroundColor: COLORS[index]}}></div>
                  <span className="text-sm text-gray-600">{entry.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;