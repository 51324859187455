import mixpanel from 'mixpanel-browser';
import { FiChevronRight, FiZap } from 'react-icons/fi';

type PopupProps = {
  featuresExplored?: number;
  totalFeatures?: number;
  trial_activated? : boolean;
  openPopup: () => void;
};

const ProgressBar : React.FC<PopupProps> = ({ featuresExplored = 3, totalFeatures = 6, openPopup, trial_activated}) => {
  const progress = (featuresExplored / totalFeatures) * 100;

  const handleSubmit = () => {
      window.location.href=('/dashboard/billing')
      mixpanel.track(`Activate Trial Button Clicked in Dashboard`,{
        email: localStorage.getItem("mailId"),
        platform: platform,
      });
  }

  // Function to detect the platform
  function detectPlatform() {
    var userAgent = navigator.userAgent || navigator.vendor ;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
        return "mobile";
    }
    return "web";
  }

  // Store the platform
  var platform = detectPlatform();

  return (
    <>
    {!trial_activated &&
      <div className="flex items-center justify-between p-4 mb-2 bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="flex items-center space-x-4">
          <div className="p-2 bg-blue-100 rounded-full">
            <FiZap className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800">{'Your free one month trial is waiting'}</h3>
            <p className="text-sm text-gray-600">{'Start your free trial to experience Outss bookkeeping solutions in action'}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <button className="flex items-center px-4 py-2 text-black bg-gray-200 rounded-md hover:bg-primary border-gray-700 hover:text-white transition-colors duration-200" onClick={() => handleSubmit()} >
            {"Activate Trial"}
            <FiChevronRight className="w-4 h-4 ml-2" />
          </button>
        </div>
      </div>
    }
    </>
  );
};

export default ProgressBar;
