import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { StreamChat, Channel as StreamChannel } from 'stream-chat';
import { Event } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useMessageListScrollManager,
  useChannelActionContext,
  ReactionSelectorProps,
  DefaultStreamChatGenerics
} from 'stream-chat-react';
// import 'stream-chat-react/dist/css/index.css';
import 'stream-chat-react/dist/css/v2/index.css';
import "./chat-styles.css";
import axios from 'axios';
import { MdSupportAgent } from 'react-icons/md';
import { FiCircle } from 'react-icons/fi';
import { FaCircle, FaCommentAlt, FaHeadset, FaThumbtack, FaUserTie } from 'react-icons/fa';
import { Tabs } from '@mantine/core';
import { getApiBaseUrl } from '../../../config/constants';
import mixpanel from 'mixpanel-browser';
import { CustomReactionSelector, CustomReactionsList } from './custom-reaction-components';

import notificationSoundPath from '../../../assets/sounds/notification.mp3';
import { useMediaQuery } from 'react-responsive';
import api from '../../../services/api';


const outssThemeStyles = `
.str-chat {
  --str-chat__primary-color: #69339C;
  --str-chat__active-primary-color: #5A2D86;
  --str-chat__surface-color: #F5F5F5;
  --str-chat__secondary-surface-color: #FFFFFF;
  --str-chat__primary-surface-color: #E5D9F2;
  --str-chat__primary-surface-color-low-emphasis: #F0E8F7;
  --str-chat__border-radius-circle: 8px;
  --str-chat__avatar-background-color: #69339C;
  --str-chat__message-bubble-color: #333333;
  --str-chat__message-bubble-background-color: #E5D9F2;
  --str-chat__message-input-background-color: #FFFFFF;
  --str-chat__message-input-border-color: #69339C;
  --str-chat__message-input-border-radius: 20px;
  --str-chat__message-input-font-size: 14px;
  --str-chat__message-input-padding: 10px 16px;
  --str-chat__message-metadata-align-items: baseline;
}
`;

interface UserData {
  id: string;
  name: string;
  image?: string;
}

interface ChatData {
  token: string;
  api_key: string;
  user_id: string;
  user_name: string;
  accountant_channel_id: string;
  success_manager_channel_id: string;
  accountant_id: string;
  accountant_name: string;
  success_manager_id: string;
  success_manager_name: string;
}
interface ChannelListProps {
  channels: { channel: StreamChannel; name: string; unreadCount: number }[];
  activeChannel: StreamChannel | null;
  onSelectChannel: (channel: StreamChannel) => void;
}

const ChannelTab: React.FC<{ 
  channel: StreamChannel; 
  name: string; 
  unreadCount: number;
  isActive: boolean;
  onClick: () => void;
  isOnline: boolean;
}> = ({ channel, name, unreadCount, isActive, onClick, isOnline }) => {
  const IconComponent = name.includes('Accountant') ? FaUserTie : FaHeadset;

  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-start px-4 py-2 text-sm font-medium ${
        isActive 
          ? 'border-b-2 border-primary text-primary' 
          : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
      }`}
    >
      <div className="flex items-center">
        <IconComponent className="w-5 h-5 mr-2" />
        <span>{name}</span>
        <FaCircle className={`w-2 h-2 ml-2 ${isOnline ? 'text-green-500' : 'text-gray-400'}`} />
        {
          isOnline ? (
            <span className="ml-1 text-xs text-green-500">Online</span>
          ) : 
          (
            <span className="ml-1 text-xs text-gray-400">Offline</span>
          )
        }
      </div>
      {unreadCount > 0 && (
        <span className="ml-2 px-2 py-1 bg-red-500 text-white text-xs font-bold rounded-full">
          {unreadCount}
        </span>
      )}
    </button>
  );
};

const PinnedMessageList: React.FC = () => {
  const { channel } = useChannelStateContext();
  const { jumpToMessage } = useChannelActionContext();
  const pinnedMessages = channel.state.pinnedMessages;

  if (pinnedMessages.length === 0) return null;

  const handlePinnedMessageClick = (messageId: string) => {
    if (jumpToMessage) {
      jumpToMessage(messageId);
    }
  };

  return (
    <div className="pinned-messages bg-gray-100 p-4 mb-4 rounded-lg shadow-sm">
      <h3 className="text-sm font-semibold mb-2 flex items-center text-gray-700">
        <FaThumbtack className="mr-2" /> Pinned Messages
      </h3>
      {pinnedMessages.map((message) => (
        <div 
          key={message.id} 
          className="text-sm cursor-pointer hover:bg-gray-200 p-2 rounded flex items-start transition-colors duration-150"
          onClick={() => handlePinnedMessageClick(message.id)}
        >
          <FaCommentAlt className="mr-2 mt-1 text-gray-500 flex-shrink-0" />
          <span className="text-gray-800">{message.text}</span>
        </div>
      ))}
    </div>
  );
};

const CustomChannelInner: React.FC = () => (
  <div className="flex flex-col h-full">
    <PinnedMessageList />
    <div className="flex-grow overflow-y-hidden">
      <MessageList 

      />
    </div>
    <div className="sticky bottom-0 bg-white border-t border-gray-200 z-10">
      <MessageInput />
    </div>
  </div>
);



const UnreadCountUpdater: React.FC<{ channelId: string; onUnreadCountChange: (channelId: string, count: number) => void }> = ({ channelId, onUnreadCountChange }) => {
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const handleEvent = () => {
      const newUnreadCount = channel.countUnread();
      onUnreadCountChange(channelId, newUnreadCount);
    };

    handleEvent(); // Initial count

    channel.on('message.new', handleEvent);
    channel.on('message.read', handleEvent);

    return () => {
      channel.off('message.new', handleEvent);
      channel.off('message.read', handleEvent);
    };
  }, [channel, channelId, onUnreadCountChange]);

  return null;
};


const StreamChatHome: React.FC = () => {
  const [client, setClient] = useState<StreamChat | null>(null);
  const [accountantChannel, setAccountantChannel] = useState<any | null>(null);
  const [successManagerChannel, setSuccessManagerChannel] = useState<any | null>(null);
  
  const [activeChannel, setActiveChannel] = useState<any | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [unreadCounts, setUnreadCounts] = useState<{ [key: string]: number }>({});

  const [notificationSound, setNotificationSound] = useState<HTMLAudioElement | null>(null);

  const [onlineStatus, setOnlineStatus] = useState<{ [key: string]: boolean }>({});

  const [accountantID, setAccountantID] = useState<string>("accountant_id");

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  useEffect(() => {
    // const audio = new Audio(notificationSoundPath);
    const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/notification.mp3`);
    setNotificationSound(audio);
    // play the audio 
    // audio.play().catch(error => console.error('Error playing sound:', error));
  
    return () => {
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, []);


  const handleUnreadCountChange = useCallback((channelId: string, count: number) => {
    setUnreadCounts(prev => {
      if (prev[channelId] !== count) {
        return { ...prev, [channelId]: count };
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    // mixpanel
    mixpanel.track('Inbox Visited');

    const requestNotificationPermission = async () => {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const handleNewMessage = async (event: any) => {
      console.log('New message event:', event);

      if (event.message && event.message.user && event.message.user.id !== client?.user?.id) {
        if (event.channel_id && event.channel_type) {
          const channel = client?.channel(event.channel_type, event.channel_id);
          await channel?.watch();

          // Play notification sound
          // notificationSound.play().catch(error => console.error('Error playing sound:', error));
          if (notificationSound) {
            notificationSound.play().catch(error => console.error('Error playing sound:', error));
          }
    

          // Display web notification
          if (Notification.permission === 'granted') {
            try {
              new Notification('New Message', {
                body: event.message.text,
                icon: '/path/to/notification-icon.png' // Add an appropriate icon
              });
            } catch (error) {
              console.error('Error creating notification:', error);
            }
          } else {
            console.log('Notification permission not granted');
          }
        } else {
          console.error('Invalid channel information in the event:', event);
        }
      }
    };

    if (client) {
      client.on('message.new', handleNewMessage);
    }

    return () => {
      if (client) {
        client.off('message.new', handleNewMessage);
      }
    };
  }, [client, notificationSound]);

  useEffect(() => {
    // Inject custom CSS
    const style = document.createElement('style');
    style.textContent = outssThemeStyles;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const enableSound = () => {
    // setIsSoundEnabled(true);
    // Attempt to play and immediately pause to "unlock" audio
    if (notificationSound) {
      notificationSound.play().then(() => {
        notificationSound.pause();
        notificationSound.currentTime = 0;
      }).catch(error => console.error('Error enabling sound:', error));
    }else{
      console.log('Notification sound is not available');
    }
  };

  useEffect(() => {
    const initChat = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await api.post<ChatData>(`${getApiBaseUrl()}/stream-chat-token/`, { user_id: userId });
        console.log('API response:', response.data);
        const { 
          token, 
          api_key, 
          user_id, 
          user_name, 
          accountant_channel_id, 
          success_manager_channel_id,
          accountant_id,
          accountant_name,
          success_manager_id,
          success_manager_name
        } = response.data;

        setAccountantID(accountant_id);

        const chatClient = StreamChat.getInstance(api_key);
        console.log('Stream Chat client:', chatClient);

        await chatClient.connectUser(
          {
            id: user_id,
            name: user_name,
            image: `https://getstream.io/random_png/?id=${user_id}&name=${user_name}`,
          },
          token
        );

        const accountantChannel = chatClient.channel('messaging', accountant_channel_id, {
          members: [user_id, accountant_id],
          name: 'Accountant Executive',
        });
        console.log('Accountant channel:', accountantChannel);

        const successManagerChannel = chatClient.channel('messaging', success_manager_channel_id, {
          members: [user_id, success_manager_id],
          name: 'Success Manager',
        });
        console.log('Success Manager channel:', successManagerChannel);

        try {
          await accountantChannel.watch();
          await successManagerChannel.watch();
          console.log('Channels watched successfully');
        } catch (watchError) {
          console.error('Error watching channels:', watchError);
        }

        setAccountantChannel(accountantChannel);
        setSuccessManagerChannel(successManagerChannel);
        setActiveChannel(accountantChannel);
        setClient(chatClient);

        chatClient.on('user.presence.changed', (event: Event) => {
          console.log('User presence changed:', event);
          if (event.user && 'id' in event.user && 'online' in event.user) {
            setOnlineStatus(prev => ({
              ...prev,
              [event?.user?.id || ""]: event?.user?.online === true,
            }));
          }
        });
  
        // Fetch initial online status
        const accountantStatus = await chatClient.queryUsers({ id: accountant_id });
        const successManagerStatus = await chatClient.queryUsers({ id: success_manager_id });
  
        setOnlineStatus(prevStatus => ({
          ...prevStatus,
          [accountant_id]: accountantStatus.users[0]?.online === true,
          [success_manager_id]: successManagerStatus.users[0]?.online === true,
        }));
  

      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };

    initChat();

    return () => {
      if (client) {
        client.disconnectUser().then(() => {
          console.log('Disconnected user');
        }).catch((error) => {
          console.error('Error disconnecting user:', error);
        });
      }
    };
  }, []);

  if (!client || !accountantChannel || !successManagerChannel) return <div>Loading...</div>;

  


  const channels = [
    { 
      channel: accountantChannel, 
      name: 'Accountant Executive', 
      unreadCount: unreadCounts[accountantChannel.id] || 0,
      isOnline: onlineStatus[accountantID] || false
    },
    // { 
    //   channel: successManagerChannel, 
    //   name: 'Success Manager', 
    //   unreadCount: unreadCounts[successManagerChannel.id] || 0,
    //   isOnline: onlineStatus[accountantID] || false
    // },
  ];

  return (
    <div className="flex flex-col h-screen">
      
      <div className="bg-white shadow z-20">
        <div className={`${isMobile ? 'w-80':'w-full'} mx-auto px-4 sm:px-6 lg:px-0`}>
          <div className="flex justify-center align-middle h-20">
          {channels.map(({ channel, name, unreadCount, isOnline }) => (
      <ChannelTab
        key={channel.id}
        channel={channel}
        name={name}
        unreadCount={unreadCount}
        isActive={channel === activeChannel}
        onClick={() => setActiveChannel(channel)}
        isOnline={isOnline}
      />
    ))}
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-hidden">
        <Chat client={client} theme="str-chat__theme-light" >
        <Channel 
            channel={activeChannel}
            // ReactionSelector={CustomReactionSelectorWrapper}
            ReactionSelector={CustomReactionSelector}
  ReactionsList={CustomReactionsList}
          >
            <UnreadCountUpdater 
              channelId={activeChannel.id}
              onUnreadCountChange={handleUnreadCountChange}
            />
            <Window>
              <CustomChannelInner />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
  );
};

export default StreamChatHome;