import React, { useState, useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import SideBarDark from '../../components/SideBarDark';
import AllClients from '../AllClients';
import BankStatement from '../BankStatement';
import ClientsView from '../ClientsView';
import DashboardAcc from '../DashboardAcc';
import UsersTable from '../UsersTable';
import UserView from '../UserView';
import AccountantLogout from '../AccountantLogout';
import './dashboard.css';
import { AccProfile } from '../MyAccount';


const DashboardRoot = () => {
  const navigate = useNavigate();
  const [runTour, setRunTour] = useState(true);

  useEffect(() => {
    const accId = localStorage.getItem('accId');
    if (!accId) {
      navigate('/accountant/login');
    }
  }, [navigate]);

  const routes = useRoutes([
    { path: 'dashboard', element: <DashboardAcc /> },
    // { path: 'bankstatement', element: <BankStatement /> },
    { path: 'clients', element: <AllClients /> },
    { path: 'business/:id', element: <ClientsView /> },
    { path: 'users', element: <UsersTable /> },
    { path: 'viewuser/:id', element: <UserView /> },
    {path: 'acclogout', element: <AccountantLogout />},
    // {path: 'myaccount', element: <AccProfile />}
  ]);

  const steps = [
    {
      target: '.sidebarUser',
      content: 'This is your sidebar menu. It contains all the main navigation options.',
      disableBeacon: true,
    },
    {
      target: '.dashboard-menu',
      content: 'Click here to view your main dashboard.',
    },
    {
      target: '.bankstatement-menu',
      content: 'Access your bank statements from here.',
    },
    {
      target: '.clients-menu',
      content: 'Manage all your clients in this section.',
    },
    {
      target: '.users-menu',
      content: 'View and manage users of the system.',
    },
    {
      target: '.logout-menu',
      content: 'Click here when you want to log out.',
    },
    {
      target: '.profit-graph-menu',
      content: 'This graph shows your profit trends over time.',
    },
  ];

 

  return (
    <div className="bg-white flex">
      
      <div className="w-1/4 nav">
        <SideBarDark />
      </div>
      <div className="w-3/4 pt-6 bg-white main">{routes}</div>
    </div>
  );
};

export default DashboardRoot;