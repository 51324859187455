import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getApiBaseUrl } from "../../../config/constants";
import uploadFileToS3 from "../../../financial-management/services/fileUploader";
import getBusiness from "../../services/getBusiness";
import '../DashboardRoot/dashboard.css';
import { useMediaQuery } from "react-responsive";

interface BankStatement {
  name: string;
  id: number;
  link: string;
  status: string;
}

interface Client {
  id: number;
  businessName: string;
  address: string;
  name: string;
  user: number;
}

interface Accountant {
  id: number;
  name: string;
  email: string;
  user_type: string;
  quickbook_id: number;
  stripe_customer_id: number;
  created_in: string;
  user_auth: number;
}

const DashboardAcc = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [showStatements, setShowStatements] = useState(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [bankStatements, setBankStatements] = useState<BankStatement[]>([]);
  const [accountant, setAccountant] = useState<Accountant | null>(null);
  const userId = localStorage.getItem("accId");
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  const {
    data = [],
    error,
    isLoading,
  } = useQuery("clients", getBusiness.getUsers);

  const fetchClientDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${getApiBaseUrl()}/user/${userId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setAccountant(response.data);
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };

  useEffect(() => {
    document.title = "Outss Accountants";
    fetchClientDetails();
    console.log(`${getApiBaseUrl()}/user/${userId}/`);    
  }, []);

  useEffect(() => {
    const fetchBankStatements = async () => {
      try {
        console.log("selectedClient", selectedClient);
        if (!selectedClient) {
          return;
        }
        const response = await fetch(
          `${getApiBaseUrl()}/user/${selectedClient?.user}/bank-statement/`
        ); // Replace "API_ENDPOINT" with the actual API endpoint
        console.log(
          `${getApiBaseUrl()}/user/${selectedClient?.user}/bank-statement/`
        );
        const data = await response.json();
        console.log(data);
        setBankStatements(data);
      } catch (error) {
        console.error("Error fetching bank statements:", error);
      }
    };

    fetchBankStatements();
  }, [selectedClient]);

  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  // Handle view button click
  const handleViewClick = (client: any) => {
    console.log("Viewing client:", client);
    setSelectedClient(client);
    setShowStatements(true);
  };

  // Handle close statements popup
  const handleCloseStatements = () => {
    setSelectedClient(null);
    setShowStatements(false);
  };

  const handleSubmitUpload = async () => {
    try {
      const response = await axios.get(
        `${getApiBaseUrl()}/user/2/upload-statement/`
      );
      console.log("Upload response:", response.data);
      if (response.data.success) {
        alert("Upload submitted successfully!");
        setShowUploadSuccess(false);
      } else {
        alert("Failed to submit the upload.");
      }
    } catch (error) {
      console.error("Error submitting upload:", error);
      alert("An error occurred while submitting the upload.");
    }
  };

  const handleFileUpload = async (event: any, statement: any) => {
    const file = event.target.files[0];

    try {
      // Upload the file to S3
      const bucketName = "outss-assets";
      const key = `bank-statements/${file.name}`;
      const csvLink = await uploadFileToS3(file, bucketName, key);
      console.log("CSV Link:", csvLink);
      // Update the API with the CSV link and other data
      const userId = localStorage.getItem("userId");
      console.log("User ID:", userId);
      const apiData = {
        csv_link: csvLink,
        is_upload: false,
        user: userId,
      };

      await axios.post(`${getApiBaseUrl()}/custom/statement/`, apiData);

      // Refresh the bank statements data
      const response = await fetch(`${getApiBaseUrl()}/user/2/bank-statement/`);
      const data = await response.json();
      setBankStatements(data);
      console.log(data);
      setShowUploadSuccess(true);
      setShowUploadSuccess(true);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className={`container mx-auto ${isMobile? 'pr-0': 'pr-10'}`}>
      {" "}
      <h1 className="text-3xl font-bold mb-8 title px-3">{`Welcome ${accountant?.name},`}</h1>{" "}
      <div className="grid grid-cols-3 gap-4 px-4 mb-8">
        {" "}
        {/* Statistics */}{" "}
        <div className="bg-white rounded-lg shadow-md px-3 py-6">
          {" "}
          <h2 className="text-xl font-semibold mb-2">Total Clients</h2>{" "}
          <p className="text-4xl font-bold">{data?.length}</p>{" "}
        </div>{" "}
        <div className="bg-white rounded-lg shadow-md px-3 py-6">
          {" "}
          <h2 className="text-xl font-semibold mb-2">
            Pending Statements
          </h2>{" "}
          <p className="text-4xl font-bold">{4}</p>{" "}
        </div>{" "}
        <div className="bg-white rounded-lg shadow-md px-3 py-6">
          {" "}
          <h2 className="text-xl font-semibold mb-2">
            Processed Statements
          </h2>{" "}
          <p className="text-4xl font-bold">{6}</p>{" "}
        </div>{" "}
        {/* ... */}{" "}
      </div>{" "}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {" "}
        <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
          {" "}
          {/* Dropdown */} {/* ... */}{" "}
          <label htmlFor="table-search" className="sr-only">
            {" "}
            Search{" "}
          </label>{" "}
          <div className="relative pl-10">
            {" "}
            <div className="absolute inset-y-0 left-10 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
              {" "}
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>{" "}
              </svg>{" "}
            </div>{" "}
            <input
              type="text"
              id="table-search"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for Customer / Business"
            />{" "}
          </div>{" "}
        </div>{" "}
        <div className="overflow-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          {" "}
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            {" "}
            <tr>
              {" "}
              <th scope="col" className="p-4">
                {" "}
                <div className="flex items-center">
                  {" "}
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />{" "}
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    {" "}
                    checkbox{" "}
                  </label>{" "}
                </div>{" "}
              </th>{" "}
              <th scope="col" className="px-6 py-3">
                {" "}
                Name{" "}
              </th>{" "}
              <th scope="col" className="px-6 py-3">
                {" "}
                Email{" "}
              </th>{" "}
              <th scope="col" className="px-6 py-3">
                {" "}
                Action{" "}
              </th>{" "}
            </tr>{" "}
          </thead>{" "}
          <tbody>
            {" "}
            {data?.map((client) => (
              <tr
                key={client.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                {" "}
                <td className="w-4 p-4">
                  {" "}
                  <div className="flex items-center">
                    {" "}
                    <input
                      id={`checkbox-table-search-${client.id}`}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />{" "}
                    <label
                      htmlFor={`checkbox-table-search-${client.id}`}
                      className="sr-only"
                    >
                      {" "}
                      checkbox{" "}
                    </label>{" "}
                  </div>{" "}
                </td>{" "}
                <th
                  scope="row"
                  className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {" "}
                  {client.businessName}{" "}
                </th>{" "}
                <td className="px-6 py-4">{client.address}</td>{" "}
                <td className="px-6 py-4">
                  {" "}
                  <button
                    onClick={() => handleViewClick(client)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    {" "}
                    View{" "}
                  </button>{" "}
                </td>{" "}
              </tr>
            ))}{" "}
          </tbody>{" "}
        </table>
        </div>{" "}
        <div className="flex justify-center mt-8">
          {" "}
          {/* Pagination */} {/* ... */}{" "}
        </div>{" "}
      </div>
      {/* {
        showStatements && 
          <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto overflow-y-auto p-4" onClick={handleCloseStatements}>
            
          <MyProfile businessId={selectedClient?.id} />
          
          </div>
        
      } */}
      {/* Bank Statements Popup */}
      {showStatements && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-4/5 lg:w-1/2 xl:w-1/2">
            <h2 className="text-xl font-semibold mb-4">
              Bank Statements for {selectedClient?.businessName}
            </h2>
            <div className="max-h-96 overflow-y-auto">
              <div className="space-y-4">
                {bankStatements?.map((statement) => (
                  <div
                    key={statement.id}
                    className={`bg-gray-100 rounded-lg p-4 flex flex-row items-center justify-between ${
                      statement.status === "Processed" ? "opacity-50" : ""
                    }`}
                  >
                    <h3 className="text-lg font-semibold mb-2">
                      {statement.link}
                    </h3>
                    {statement.status === "Processed" ? (
                      <span className="bg-green-500 text-white px-2 py-1 rounded-full text-xs">
                        Processed
                      </span>
                    ) : (
                      <span className="bg-yellow-500 text-white px-2 py-1 rounded-full text-xs">
                        Pending
                      </span>
                    )}
                    <a
                      href={statement.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline flex items-center mb-4"
                    >
                      <svg
                        className="w-4 h-4 mr-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Download
                    </a>
                    <label
                      htmlFor={`file-upload-${statement.id}`}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded cursor-pointer"
                    >
                      Upload CSV
                    </label>
                    <input
                      id={`file-upload-${statement.id}`}
                      type="file"
                      accept=".csv"
                      className="hidden"
                      onChange={(event) => handleFileUpload(event, statement)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handleCloseStatements}
              className="mt-6 bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showUploadSuccess && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Upload Successful</h2>
            <p className="mb-4">The CSV file has been uploaded successfully.</p>
            <button
              onClick={handleSubmitUpload}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mr-4"
            >
              Submit
            </button>
            <button
              onClick={() => setShowUploadSuccess(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {/* Upload Success Popup */}
    </div>
  );
};

export default DashboardAcc;
