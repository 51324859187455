import React from 'react';

interface CardProps {
  title: string;
  amount: string;
  icon?: React.ReactNode;
  bgColor?: string;
}

const Card = ({ title, amount, icon, bgColor = 'bg-white' }: CardProps) => {
  return (
    <div className={`${bgColor} p-6 rounded-lg shadow-sm border hover:bg-[#e0e0e0] transition-colors duration-300`}>
      <div className="flex items-center mb-4">
        {icon && <div className="mr-4">{icon}</div>}
        <h2 className="text-lg outssText font-medium text-[#69339C]">{title}</h2>
      </div>
      <p className="text-3xl outssnumbers text-[#69339C]">{amount}</p>
    </div>
  );
};

export default Card;