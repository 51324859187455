import React, { useState } from 'react';
import { FaCreditCard, FaLink, FaRocket, FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PostTourDialog = ({ isOpen, onClose }:{
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleNext = () => {
    if (step === 1) {
      onClose(); // <= if need connect bank account, add setStep(2) here
    } else {
      onClose();
    }
  };

  const handleActivateTrial = () => {
    // Redirect to the trial activation page
    navigate('/dashboard/billing');
    onClose();
  };

  const handleConnectBank = () => {
    // Redirect to the bank connection page
    navigate('/dashboard/mybooks');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full">
        {/* <h1 className="text-3xl font-bold text-center mb-6 text-purple-700">Get 1 Month of free accounting</h1> */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-center mb-6 text-primary">
            {step === 1 ? 'Get 1 Month of free accounting' : 'Connect Bank Accounts with Plaid'}
          </h2>
          <p className="text-gray-600 mb-4">
            {step === 1 
              ? 'Start your complimentary 30-day trial to access all premium features.' 
              : 'Connect your bank accounts to streamline your financial management and get real-time insights.'}
          </p>
        </div>
        <div className="flex justify-center items-center p-4 mb-6">
          {step === 1 ? (
            <FaRocket className="text-8xl text-primary" />
          ) : (
            <FaLink className="text-8xl text-primary" />
          )}
        </div>
        <div className="space-y-4 mb-8">
          <div className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" />
            <span>{step === 1 ? 'Access to all premium features' : 'Automatic transaction categorization'}</span>
          </div>
          <div className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" />
            <span>{step === 1 ? 'Priority customer support' : 'Real-time balance updates'}</span>
          </div>
          <div className="flex items-center">
            <FaCheckCircle className="text-green-500 mr-2" />
            <span>{step === 1 ? 'Advanced reporting tools' : 'Secure and encrypted connection'}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <button 
            onClick={step === 1 ? handleActivateTrial : handleConnectBank}
            className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary transition duration-300 flex items-center"
          >
            {step === 1 ? <FaCreditCard className="mr-2" /> : <FaLink className="mr-2" />}
            {step === 1 ? 'Activate Trial' : 'Connect Bank'}
          </button>
          <button 
            onClick={handleNext}
            className="bg-gray-200 text-gray-800 px-6 py-3 rounded-lg hover:bg-gray-300 transition duration-300"
          >
            {step === 1 ? 'Skip for now' : 'Skip for now'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostTourDialog;