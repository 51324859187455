import React from 'react';
import { IconType } from 'react-icons';

interface AlertPopupProps {
  icon: IconType;
  message: string;
}

const AlertPopup: React.FC<AlertPopupProps> = ({ icon: Icon, message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50 ">
      <div className="relative bg-white rounded-lg shadow-lg p-6 w-full mx-auto max-w-xl py-10">
        <div className="flex items-center justify-center">
          <Icon className="text-6xl text-white bg-orange-300 rounded-full p-2" />
        </div>
        <div className="mt-8">
          <h2 className="text-gray-800 text-center">{message}</h2>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;