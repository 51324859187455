import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { getApiBaseUrl } from '../../../config/constants';

interface Document {
  id: number;
  link: string;
  status: 'pending' | 'inprogress' | 'completed';
  is_filed: boolean;
  user: number;
}

const BankStatements: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [updatedDocument, setUpdatedDocument] = useState<Document | null>(null);

  const useURLParams = (): URLSearchParams => {
    const location = useLocation();
    return new URLSearchParams(location.search);
  };

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const url = new URL(window.location.href);
    const userID = parseInt(url.searchParams.get('userid') || '1');
    console.log(userID);

    const fetchDocuments = async () => {
      try {
        const response = await axios.get<Document[]>(`${getApiBaseUrl()}/user/${userID}/bank-statement/`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, []);

  const openDialog = (document: Document) => {
    setSelectedDocument(document);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedDocument(null);
    setIsDialogOpen(false);
  };

  const openUpdateDialog = (document: Document) => {
    setUpdatedDocument(document);
    setIsUpdateDialogOpen(true);
  };

  const closeUpdateDialog = () => {
    setUpdatedDocument(null);
    setIsUpdateDialogOpen(false);
  };

  const getFilePreview = (document: Document) => {
    const fileType = document.link.split('.').pop();

    if (fileType === 'pdf') {
      return (
        <embed
          src={document.link}
          type="application/pdf"
          width="100%"
          height="600px"
        />
      );
    } else if (fileType === 'docx') {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(document.link)}`}
          width="100%"
          height="600px"
          frameBorder="0"
        />
      );
    }

    return null;
  };

  const downloadFile = (document: Document) => {
    const link = document.link;
    const fileName = decodeURIComponent(link.split('/').pop() || '');
    window.open(link, '_blank');
  };

  const updateBankStatement = async () => {
    if (updatedDocument) {
      try {
        await axios.put(`${getApiBaseUrl()}/bank-statement/${updatedDocument.id}/`, updatedDocument);
        closeUpdateDialog();
        // Refresh the document list after updating
        const url = new URL(window.location.href);
        const userID = parseInt(url.searchParams.get('userid') || '1');
        const response = await axios.get<Document[]>(`${getApiBaseUrl()}/user/${userID}/bank-statement/`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error updating bank statement:', error);
      }
    }
  };  

  return (
    <div className="container mx-auto mt-3">
      <h3 className="text-2xl font-semibold mb-6">Document List</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((document) => (
          <div
            key={document.id}
            className="bg-white rounded-lg shadow-md p-6 cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
            onClick={() => openDialog(document)}
          >
            <p className="text-gray-600 mb-4">
              {/* {decodeURIComponent(document.link.split('/').pop() || '')} */}
              {document.link}
            </p>
            <div className="flex justify-between items-center mb-4">
              <span className={`text-sm font-semibold ${document.status === 'pending' ? 'text-yellow-500' : document.status === 'inprogress' ? 'text-blue-500' : 'text-green-500'}`}>
                {document.status}
              </span>
              <span className="text-sm text-gray-500">User: {document.user}</span>
            </div>
            <div className="mb-4">
              <span className={`text-sm ${document.is_filed ? 'text-green-500' : 'text-red-500'}`}>
                {document.is_filed ? 'Filed' : 'Not Filed'}
              </span>
            </div>
            <div className="flex space-x-4">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  openDialog(document);
                }}
              >
                View
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile(document);
                }}
              >
                Download
              </button>
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  openUpdateDialog(document);
                }}
              >
                Update
              </button>
            </div>
          </div>
        ))}
      </div>

      {isDialogOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl w-96 p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Document Details</h3>
            {selectedDocument && (
              <div className="space-y-2 mb-6">
                <p><span className="font-semibold">ID:</span> {selectedDocument.id}</p>
                <p><span className="font-semibold">Link:</span> {selectedDocument.link}</p>
                <p><span className="font-semibold">Status:</span> {selectedDocument.status}</p>
                <p><span className="font-semibold">Is Filed:</span> {selectedDocument.is_filed ? 'Yes' : 'No'}</p>
                <p><span className="font-semibold">User:</span> {selectedDocument.user}</p>
              </div>
            )}
            {selectedDocument && getFilePreview(selectedDocument)}
            <div className="mt-6 text-right">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={closeDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isUpdateDialogOpen && updatedDocument && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Update Bank Statement</h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="link" className="block text-sm font-medium text-gray-700">Link</label>
                <input
                  type="text"
                  id="link"
                  className="mt-1 w-full rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={updatedDocument.link}
                  onChange={(e) => setUpdatedDocument({ ...updatedDocument, link: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                <select
                  id="status"
                  className="mt-1 w-full rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={updatedDocument.status}
                  onChange={(e) => setUpdatedDocument({ ...updatedDocument, status: e.target.value as 'pending' | 'inprogress' | 'completed' })}
                >
                  <option value="pending">Pending</option>
                  <option value="in-progress">In Progress</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isFiled"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={updatedDocument.is_filed}
                  onChange={(e) => setUpdatedDocument({ ...updatedDocument, is_filed: e.target.checked })}
                />
                <label htmlFor="isFiled" className="ml-2 text-sm font-medium text-gray-700">Is Filed</label>
              </div>
              <div>
                <label htmlFor="user" className="block text-sm font-medium text-gray-700">User</label>
                <input
                  type="number"
                  id="user"
                  className="mt-1 w-full rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={updatedDocument.user}
                  onChange={(e) => setUpdatedDocument({ ...updatedDocument, user: parseInt(e.target.value) })}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={closeUpdateDialog}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={updateBankStatement}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankStatements;