import React, { useEffect, useState, useCallback } from 'react';
import { StreamChat, Channel as StreamChannel, Event } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
  useChannelActionContext,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import "../StreamChatHome/chat-styles.css";
import axios from 'axios';
import { FaCommentAlt, FaThumbtack, FaUserTie } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getApiBaseUrl } from '../../../config/constants';

const outssThemeStyles = `
.str-chat {
  --str-chat__primary-color: #69339C;
  --str-chat__active-primary-color: #5A2D86;
  --str-chat__surface-color: #F5F5F5;
  --str-chat__secondary-surface-color: #FFFFFF;
  --str-chat__primary-surface-color: #E5D9F2;
  --str-chat__primary-surface-color-low-emphasis: #F0E8F7;
  --str-chat__border-radius-circle: 8px;
  --str-chat__avatar-background-color: #69339C;
  --str-chat__message-bubble-color: #333333;
  --str-chat__message-bubble-background-color: #E5D9F2;
  --str-chat__message-input-background-color: #FFFFFF;
  --str-chat__message-input-border-color: #69339C;
  --str-chat__message-input-border-radius: 20px;
  --str-chat__message-input-font-size: 14px;
  --str-chat__message-input-padding: 10px 16px;
}
`;

interface ChatData {
  token: string;
  api_key: string;
  accountant_id: string;
  accountant_name: string;
  user_id: string;
  user_name: string;
  channel_id: string;
}

function showCustomNotification(title: string, body: string, icon?: string) {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
    return;
  }

  if (Notification.permission === "granted") {
    const options = {
      body: body,
      icon: icon || "/path/to/outss-icon.png", // Replace with your app's icon
      badge: "/path/to/outss-badge.png", // A small monochrome icon
      tag: "outss-notification",
      renotify: true,
      silent: false,
    };

    const notification = new Notification(title, options);

    notification.onclick = function() {
      window.focus();
      notification.close();
    };
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        showCustomNotification(title, body, icon);
      }
    });
  }
}

const PinnedMessageList: React.FC = () => {
  const { channel } = useChannelStateContext();
  const { jumpToMessage } = useChannelActionContext();
  const pinnedMessages = channel.state.pinnedMessages;

  if (pinnedMessages.length === 0) return null;

  const handlePinnedMessageClick = (messageId: string) => {
    if (jumpToMessage) {
      jumpToMessage(messageId);
    }
  };

  return (
    <div className="pinned-messages bg-gray-100 p-4 mb-4 rounded-lg shadow-sm">
      <h3 className="text-sm font-semibold mb-2 flex items-center text-gray-700">
        <FaThumbtack className="mr-2" /> Pinned Messages
      </h3>
      {pinnedMessages.map((message) => (
        <div 
          key={message.id} 
          className="text-sm cursor-pointer hover:bg-gray-200 p-2 rounded flex items-start transition-colors duration-150"
          onClick={() => handlePinnedMessageClick(message.id)}
        >
          <FaCommentAlt className="mr-2 mt-1 text-gray-500 flex-shrink-0" />
          <span className="text-gray-800">{message.text}</span>
        </div>
      ))}
    </div>
  );
};

const CustomChannelInner: React.FC = () => (
  <div className="flex flex-col h-full">
    <PinnedMessageList />
    <div className="flex-grow overflow-y-auto">
      <MessageList />
    </div>
    <div className="sticky bottom-0 bg-white border-t border-gray-200 z-10">
      <MessageInput />
    </div>
  </div>
);

const UnreadCountUpdater: React.FC<{ channelId: string; onUnreadCountChange: (count: number) => void }> = ({ channelId, onUnreadCountChange }) => {
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const handleEvent = () => {
      const newUnreadCount = channel.countUnread();
      onUnreadCountChange(newUnreadCount);
    };

    handleEvent(); // Initial count

    channel.on('message.new', handleEvent);
    channel.on('message.read', handleEvent);

    return () => {
      channel.off('message.new', handleEvent);
      channel.off('message.read', handleEvent);
    };
  }, [channel, channelId, onUnreadCountChange]);

  return null;
};

const StreamChatAccountant: React.FC = () => {
  const [client, setClient] = useState<StreamChat | null>(null);
  const [channel, setChannel] = useState<any | null>(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();

  const handleUnreadCountChange = useCallback((count: number) => {
    setUnreadCount(count);
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = outssThemeStyles;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    };
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    const initChat = async () => {
      try {
        const accountantId = localStorage.getItem('accId');
        if (!accountantId) {
          throw new Error('Accountant ID not found');
        }

        const response = await axios.post<ChatData>(`${getApiBaseUrl()}/accountant-stream-chat-token/`, {
          accountant_id: accountantId,
          user_id: id
        });
        console.log('API response:', response.data);

        const { 
          token, 
          api_key, 
          accountant_id, 
          accountant_name, 
          user_id, 
          user_name, 
          channel_id 
        } = response.data;
    
        const chatClient = StreamChat.getInstance(api_key);
        console.log('Stream Chat client:', chatClient);
    
        await chatClient.connectUser(
          {
            id: accountant_id,
            name: accountant_name,
            image: `https://getstream.io/random_png/?id=${accountant_id}&name=${accountant_name}`,
          },
          token
        );
    
        const chatChannel = chatClient.channel('messaging', channel_id, {
          members: [accountant_id, user_id],
          name: `Chat with ${user_name}`,
        });
        console.log('Chat channel:', chatChannel);
        
        try {
          await chatChannel.watch();
          console.log('Channel watched successfully');
          setChannel(chatChannel);
          setClient(chatClient);
        } catch (watchError) {
          console.error('Error watching channel:', watchError);
          setError('Failed to watch channel');
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
        setError('Failed to initialize chat');
      }
    };

    if (id) {
      initChat();
    }

    return () => {
      if (client) {
        client.disconnectUser().catch((error) => {
          console.error('Error disconnecting accountant:', error);
        });
      }
    };
  }, [id]);

  useEffect(() => {
    if (!client || !client.user) return;

    const handleNewMessage = (event: Event) => {
      console.log('New message event:', event);
      if (event.type === 'message.new' && event.user?.id !== client.user?.id) {
        if (Notification.permission === 'granted') {
          new Notification('New Message', {
            body: event.message?.text || 'You have a new message',
          });
        }
      }
    };

    client.on('message.new', handleNewMessage);

    return () => {
      client.off('message.new', handleNewMessage);
    };
  }, [client]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!client || !channel) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-screen">
      
      <div className="flex-grow overflow-hidden">
        <Chat client={client} theme="str-chat__theme-light">
          <Channel channel={channel}>
            <UnreadCountUpdater
              channelId={channel.id}
              onUnreadCountChange={handleUnreadCountChange}
            />
            <Window>
              <CustomChannelInner />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
  );
};

export default StreamChatAccountant;