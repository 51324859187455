import React, { useState } from "react";

import LoadingScreen from "../../../components/LoadingScreen";
import createBusinessDetails, { FormData } from "../../../pages/OnBoarding/hooks";
import logo from "../../../assets/logo.png";
import welcomeIcon from "../../../assets/icons/welcome.png";

import { GrSave } from "react-icons/gr";
import { BsArrowRight } from "react-icons/bs";

const WelcomeBusiness: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [otherIndustryType, setOtherIndustryType] = useState("");
  const [otherAccountingSoftware, setOtherAccountingSoftware] = useState("");
  const [businessDetails, setBusinessDetails] = useState<FormData>({
    businessName: "",
    legalStructure: "",
    industryType: "",
    businessModel: "",
    accountingSoftware: "",
    chartOfAccounts: false,
    address: "",
    taxIdNumber: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const target = e.target as HTMLInputElement;
    const { name, value, type, checked } = target;
    setBusinessDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !businessDetails.businessName ||
      !businessDetails.legalStructure ||
      !businessDetails.industryType ||
      !businessDetails.address
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    setIsLoading(true);
    const res = await createBusinessDetails({
      ...businessDetails,
      industryType:
        businessDetails.industryType === "Other"
          ? otherIndustryType
          : businessDetails.industryType,
      accountingSoftware: "",
      // accountingSoftware:
      //   businessDetails.accountingSoftware === "Other"
      //     ? otherAccountingSoftware
      //     : businessDetails.accountingSoftware,
    });
    setIsLoading(false);

    if (res.id) {
      window.location.href = "/dashboard";
    } else {
      alert("Error: " + res.message);
    }
  };

  const industryTypes = [
    "Technology",
    "Finance",
    "Healthcare",
    "Education",
    "Retail",
    "Automotive",
    "Hospitality",
    "Real Estate",
    "Entertainment",
    "Fashion",
    "Food and Beverage",
    "Travel",
    "Media",
    "Telecommunication",
    "Energy",
    "Manufacturing",
    "Construction",
    "Transportation",
    "Agriculture",
    "Government",
    "Other",
  ];
  const legalStructures = [
    "Sole Proprietorship",
    "Partnership",
    "Limited Liability Company (LLC)",
    "Corporation",
    "Non-Profit",
  ];
  // const accountingSoftwares = [
  //   "QuickBooks",
  //   "Xero",
  //   "FreshBooks",
  //   "Wave",
  //   "Sage",
  //   "Zoho Books",
  //   "NetSuite",
  //   "Microsoft Dynamics GP",
  //   "SAP Business One",
  //   "Oracle Financials",
  //   "Other",
  // ];

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="min-h-screen w-full flex flex-col md:flex-row items-center justify-center bg-white">
      {/* Left side - Welcome message */}
      <div className="hidden md:flex w-full md:w-1/2 min-h-screen p-8 overflow-y-auto flex-col justify-center bg-white">
        <div className="w-full max-w-md mx-auto flex-col justify-center p-8  bg-white rounded-md">
          <img className="h-20 w-auto mx-auto mb-8" src={logo} alt="Outss Logo" />
          <h1 className="text-3xl font-bold mb-4 text-center">Welcome to Outss!</h1>
          <img className="h-12 w-auto mx-auto mb-8" src={welcomeIcon} alt="Outss Logo" />
          <p className="text-xl text-center text-gray-600 mb-4">
            Thank you for signing up. We're excited to help you manage your business finances.
          </p>
          <p className="text-center text-gray-600">
            Please take a moment to fill in your business details to get started.
          </p>
        </div>
      </div>

      {/* Right side - Business details form */}
      <div className="w-full md:w-1/2 min-h-screen p-8 overflow-y-auto flex flex-col justify-center">
        <div className="max-w-md mx-auto w-full">
          {/* Show logo on mobile */}
          <img className="h-12 w-auto mx-auto mb-8 md:hidden" src={logo} alt="Outss Logo" />
          
          <h2 className="text-2xl font-bold mb-4">Fill in your business details</h2>
          <p className="text-gray-600 mb-6">Please provide your business details to get started.</p>
          
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">
                Business Name
              </label>
              <input
                type="text"
                name="businessName"
                id="businessName"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.businessName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="legalStructure" className="block text-sm font-medium text-gray-700">
                Legal Structure
              </label>
              <select
                name="legalStructure"
                id="legalStructure"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.legalStructure}
                onChange={handleChange}
              >
                <option value="">Select Legal Structure</option>
                {legalStructures.map((structure) => (
                  <option key={structure} value={structure}>
                    {structure}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="industryType" className="block text-sm font-medium text-gray-700">
                Industry Type
              </label>
              <select
                name="industryType"
                id="industryType"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.industryType}
                onChange={handleChange}
              >
                <option value="">Select Industry</option>
                {industryTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {businessDetails.industryType === "Other" && (
              <div>
                <label htmlFor="otherIndustryType" className="block text-sm font-medium text-gray-700">
                  Other Industry Type
                </label>
                <input
                  type="text"
                  name="otherIndustryType"
                  id="otherIndustryType"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                  value={otherIndustryType}
                  onChange={(e) => setOtherIndustryType(e.target.value)}
                />
              </div>
            )}

            <div>
              <label htmlFor="businessModel" className="block text-sm font-medium text-gray-700">
                Business Model
              </label>
              <textarea
                name="businessModel"
                id="businessModel"
                rows={2}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.businessModel}
                onChange={handleChange}
              ></textarea>
            </div>

            {/* <div>
              <label htmlFor="accountingSoftware" className="block text-sm font-medium text-gray-700">
                Accounting Software
              </label>
              <select
                name="accountingSoftware"
                id="accountingSoftware"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.accountingSoftware}
                onChange={handleChange}
              >
                <option value="">Select Accounting Software</option>
                {accountingSoftwares.map((software) => (
                  <option key={software} value={software}>
                    {software}
                  </option>
                ))}
              </select>
            </div>

            {businessDetails.accountingSoftware === "Other" && (
              <div>
                <label htmlFor="otherAccountingSoftware" className="block text-sm font-medium text-gray-700">
                  Other Accounting Software
                </label>
                <input
                  type="text"
                  name="otherAccountingSoftware"
                  id="otherAccountingSoftware"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                  value={otherAccountingSoftware}
                  onChange={(e) => setOtherAccountingSoftware(e.target.value)}
                />
              </div>
            )} */}

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                name="address"
                id="address"
                rows={3}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                value={businessDetails.address}
                onChange={handleChange}
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {isLoading ? "Submitting..." : "Submit & Get Started"}
                <BsArrowRight className="ml-2" />
              </button>
            </div>
          </form>
          

        </div>
      </div>
    </div>
  );
};

export default WelcomeBusiness;