import React, { useState, useEffect } from 'react';
import { FiBook, FiClipboard, FiFileText } from 'react-icons/fi';

interface Slide {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
}
const slides: Slide[] = [
  {
    id: 1,
    icon: <FiBook size={50} />,
    title: 'Outss Bookkeeping',
    description: 'Efficiently manage your finances with our comprehensive bookkeeping tools.'
  },
  {
    id: 2,
    icon: <FiFileText size={50} />,
    title: 'Outss Invoicing',
    description: 'Simplify your billing process with our easy-to-use invoicing feature.'
  },
  {
    id: 3,
    icon: <FiClipboard size={50} />,
    title: 'Outss Project Management',
    description: 'Stay organized and keep your projects on track with our robust project management tools.'
  }
];

const FeaturesSlider: React.FC = ()=> {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(function (prevIndex) {
        return (prevIndex + 1) % slides.length;
      });
      
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full max-w-md mx-auto overflow-hidden h-[400px]">
      <div
        className="flex h-full transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="w-full flex-shrink-0 flex flex-col items-center justify-center p-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{slide.title}</h2>
            <div className="text-8xl mb-8">{slide.icon}</div>
            <p className="text-xl text-center text-gray-600">{slide.description}</p>
          </div>
        ))}
      </div>
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-primary' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturesSlider;