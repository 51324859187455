import { getApiBaseUrl } from "../../config/constants";

// [{"id":1,"businessName":"RISHI Autos","legalStructure":"","taxIdNumber":"5BEBPA6768FF","address":"Valayapettai agraharam","contactInfo":"None","ownerName":"Arun","ownerContactInfo":"99131 69895","preferredCommunication":"","industryType":"","businessModel":"None","accountingSoftware":"","chartOfAccounts":false,"desiredOutcomes":"None","painPoints":"None","user":1}]
interface BusinessDataType { 
  id: number;
  businessName: string;
  legalStructure: string;
  taxIdNumber: string;
  address: string;
  contactInfo: string;
  ownerName: string;
  ownerContactInfo: string;
  preferredCommunication: string;
  industryType: string;
  businessModel: string;
  accountingSoftware: string;
  chartOfAccounts: boolean;
  desiredOutcomes: string;
  painPoints: string;
  user: number;

}
async function getUsers(): Promise<BusinessDataType[]> {
  const response = await fetch(`${getApiBaseUrl()}/business-details/`);
  const data = await response.json();
  return data;
}

async function getUserById(id: number): Promise<BusinessDataType> {
  const response = await fetch(`${getApiBaseUrl()}/business-details/${id}`);
  const data = await response.json();
  return data;
}


export default  { getUsers, getUserById };