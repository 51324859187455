import { CheckIcon } from '@mantine/core';
import React, { useState, useEffect } from 'react';

interface Feature {
  title: string;
  description: string;
}

const features: Feature[] = [
  {
    title: "Dedicated bookkeeper & unlimited support",
    description: "Chat with a dedicated accountant 24/7. It's like having a CFO in your pocket!"
  },
  {
    title: "Monthly reports & tax-ready financials",
    description: "Balance sheets and income statements so clear, even your dog could understand them."
  },
  {
    title: "Real-time financial dashboard",
    description: "Plaid integration connects your accounts faster than you can say 'reconciliation'."
  }
];

const FeatureList: React.FC = () => {
  const [currentFeature, setCurrentFeature] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 5000); // Change feature every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white p-6 mb-2">
      {/* <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Features</h2> */}
      {/* A check mark and a list of all titles */}
      
        {features.map((feature, index) => (
          <div key={index} className={`flex items-center space-x-6 font-bold text-gray-800`}>
            <CheckIcon className="text-green-500 mr-4" size={14} />
            {feature.title}
          </div>
        ))}
    </div>
  );
};

export default FeatureList;