import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCheckCircle, FaTimesCircle, FaExclamationCircle } from 'react-icons/fa';
import { getServerUrl } from '../../config/constants';

const EmailVerification = () => {
  const [status, setStatus] = useState('verifying');
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(getServerUrl()+`/api/activate/user-account/${token}/`);
        if (response.data.success) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error:any) {
        if (error.response && error.response.data) {
          if (error.response.data.response.includes("Token is expired")) {
            setStatus('expired');
          } else if (error.response.data.response.includes("Invalid token")) {
            setStatus('invalid');
          } else {
            setStatus('error');
          }
        } else {
          setStatus('error');
        }
      }
    };

    verifyEmail();
  }, [token]);

  const getStatusContent = () => {
    switch (status) {
      case 'verifying':
        return {
          icon: <FaExclamationCircle className="w-16 h-16 text-yellow-500 animate-pulse text-center" />,
          title: 'Verifying Your Email',
          message: 'Please wait while we verify your email address...'
        };
      case 'success':
        return {
          icon: <FaCheckCircle className="w-16 h-16 text-green-500 text-center" />,
          title: 'Email Verified',
          message: 'Your email has been successfully verified. You can now log in to your account.'
        };
      case 'expired':
        return {
          icon: <FaTimesCircle className="w-16 h-16 text-red-500 text-center" />,
          title: 'Verification Link Expired',
          message: 'The verification link has expired. Please request a new verification email.'
        };
      case 'invalid':
        return {
          icon: <FaTimesCircle className="w-16 h-16 text-red-500" />,
          title: 'Invalid Verification Link',
          message: 'The verification link is invalid. Please check your email or request a new verification link.'
        };
      default:
        return {
          icon: <FaTimesCircle className="w-16 h-16 text-red-500 text-center" />,
          title: 'Verification Failed',
          message: 'An error occurred during email verification. Please try again or contact support.'
        };
    }
  };

  const { icon, title, message } = getStatusContent();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 text-center">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full text-white">
            {icon}
            </div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {message}
            </p>
          </div>
          {status !== 'verifying' && (
            <div className="mt-6">
              <button
                onClick={() => navigate('/')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Go to Login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;