import React, { useEffect, useState } from "react";
import CustomDatePicker from '../../../components/CustomDatePicker';
import { FaCalendar, FaExclamationCircle, FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { getHumanReadableDate } from "../../utils";
import AlertPopup from '../../../components/AlertPopup';
import { getApiBaseUrl, getServerUrl } from '../../../config/constants';
import mixpanel from "mixpanel-browser";

interface Transaction {
  transaction_type: string;
  name: string;
  category: string;
  amount: string;
}

interface TransactionDate {
  transaction_date: string;
  transaction_list: Transaction[];
}

interface Report {
  id: number;
  transaction_date: string;
  transaction_name: string;
  transaction_category: string;
  message: string;
  status: string;
}

type DayRange = {
  from: Date | undefined;
  to: Date | undefined;
};

const TransactionTable: React.FC = () => {
  const [transactions, setTransactions] = useState<TransactionDate[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DayRange>({
    from: new Date("2024-03-01"),
    to: new Date("2024-03-31"),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
  const [reportMessage, setReportMessage] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [reportStatus, setReportStatus] = useState<"success" | "error" | null>(null);

  useEffect(() => {
    fetchTransactions();
    fetchReports();
  }, [selectedDateRange]);

  useEffect(() => {
    mixpanel.track("Transaction List Visited",{
      Email: localStorage.getItem("mailId"),
      Plotform: platform,
    });
  }, []);

    // Function to detect the platform
    function detectPlatform() {
      var userAgent = navigator.userAgent || navigator.vendor ;
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window) {
          return "mobile";
      }
      return "web";
    }
  
    // Store the platform
    var platform = detectPlatform();

  const fetchTransactions = async () => {
    try {
      const fromDate = selectedDateRange.from?.toISOString().split("T")[0];
      const toDate = selectedDateRange.to?.toISOString().split("T")[0];
      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${getApiBaseUrl()}/transaction-statement/${userId}/?start_date=${fromDate}&end_date=${toDate}`,{
          headers:{
            'authorization': `Token ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();

      if (data.success === false && data.response === "user not found") {
        setErrorMessage("User not found");
      } 
      else if (data.success === false ){
        setErrorMessage("No Transactions found.");
      }
      else {
        setTransactions(data.response);
        setErrorMessage("");
      }
      
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setErrorMessage("An error occurred while fetching transactions");
    }
  };

  const fetchReports = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await fetch(`${getServerUrl()}/transaction-reports-list/?userid=${userId}`,{
        headers:{
          'authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    
    );
      const data = await response.json();
      setReports(data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const openDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const closeDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const handleDateChange = (dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => {
    setSelectedDateRange({ from: dateRange.startDate, to: dateRange.endDate });
  };

  const isTransactionReported = (transaction: Transaction, date: string) => {
    return reports.some(
      (report) =>
        report.transaction_date === date &&
        report.transaction_name === transaction.name &&
        report.transaction_category === transaction.category
    );
  };

  const openReportPopup = (transaction: Transaction) => {
    if (!isTransactionReported(transaction, transactions.find(t => t.transaction_list.includes(transaction))?.transaction_date || '')) {
      setSelectedTransaction(transaction);
      setIsReportPopupOpen(true);
    }
  };

  const closeReportPopup = () => {
    setIsReportPopupOpen(false);
    setReportMessage("");
    setSelectedTransaction(null);
    setReportStatus(null);
  };

  const handleReportSubmit = async () => {
    if (selectedTransaction) {
      const { name, category } = selectedTransaction;
      const transactionDate = transactions.find((transactionDate) =>
        transactionDate.transaction_list.some((transaction) => transaction.name === name && transaction.category === category)
      )?.transaction_date;

      if (transactionDate) {
        try {
          const userId = localStorage.getItem("userId");
          const response = await fetch(`${getServerUrl()}/transaction-reports/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user: userId,
              transaction_date: transactionDate,
              transaction_name: name,
              transaction_category: category,
              message: reportMessage,
            }),
          });

          if (response.ok) {
            setReportStatus("success");
            fetchReports(); // Refresh reports after successful submission
            setTimeout(() => {
              closeReportPopup();
            }, 2000);
          } else {
            setReportStatus("error");
          }
        } catch (error) {
          console.error("Error submitting report:", error);
          setReportStatus("error");
        }
      }
    }
  };

  return (
    <div>
      <div className="fixed h-20 border-b px-4 lg:px-2 lg:pr-0 sm:pl-24 md:pl-24 justify-between items-center flex md:hidden flex-row lg:flex-row w-full bg-white z-10">
        <h1 className="text-2xl font-bold text-center lg:text-left mb-2 lg:mb-0 ml-14">
          Transaction List
        </h1>

        <div className="flex items-center ml-0 lg:ml-auto"></div>
      </div>
      <div className="w-full flex flex-col items-center bg-white pt-24 md:pt-0 p-6 mt-0 ">
        {isDatePickerOpen && (
          <CustomDatePicker
            onClose={closeDatePicker}
            onDateChange={handleDateChange}
            from="TransactionTable"
          />
        )}

        <div className="hidden md:flex justify-between items-center mt-6 w-full">
          <h1 className="text-2xl font-bold">Transaction List</h1>
          <button
            onClick={openDatePicker}
            className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none"
          >
            <FaCalendar className="text-gray-400" />
            <span>
              From:{" "}
              {getHumanReadableDate(selectedDateRange.from || new Date()) ||
                "From Date"}{" "}
              - To:{" "}
              {getHumanReadableDate(selectedDateRange.to || new Date()) ||
                "To Date"}
            </span>
          </button>
        </div>

        <button
          onClick={openDatePicker}
          className="flex md:hidden w-full items-center space-x-2 px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none"
        >
          <FaCalendar className="text-gray-400" />
          <span>
            From:{" "}
            {getHumanReadableDate(selectedDateRange.from || new Date()) ||
              "From Date"}{" "}
            - To:{" "}
            {getHumanReadableDate(selectedDateRange.to || new Date()) ||
              "To Date"}
          </span>
        </button>

        {errorMessage && (
        <AlertPopup icon={FaExclamationCircle} message={errorMessage} />
      )}

      {transactions.length > 0 ? (
        <div className="w-full mt-6 overflow-x-auto">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Date</th>
                <th className="py-3 px-6 text-left">Type</th>
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Category</th>
                <th className="py-3 px-6 text-right">Amount</th>
                <th className="py-3 px-6 text-center">Status</th>
                <th className="py-3 px-6 text-center">Feedback</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
              {transactions.flatMap((transactionDate, index) =>
                transactionDate.transaction_list.map(
                  (transaction, transactionIndex) => (
                    <tr
                      key={`${transactionDate.transaction_date}-${transaction.name}`}
                      className={`border-b ${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}
                    >
                      <td className="py-4 px-6">
                        {transactionDate.transaction_date}
                      </td>
                      <td className="py-4 px-6">
                        {transaction.transaction_type}
                      </td>
                      <td className="py-4 px-6">{transaction.name}</td>
                      <td className="py-4 px-6">{transaction.category}</td>
                      <td
                        className={`py-4 px-6 text-right font-medium ${
                          parseFloat(transaction.amount) >= 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {parseFloat(transaction.amount) >= 0 ? "+" : "-"}$
                        {Math.abs(parseFloat(transaction.amount)).toFixed(2)}
                      </td>
                      <td className="py-4 px-6 text-center">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          <FaCheck className="mr-1 h-3 w-3 text-green-500" />
                          Paid
                        </span>
                      </td>
                      <td className="py-4 px-6 text-center">
                        {isTransactionReported(transaction, transactionDate.transaction_date) ? (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            <FaCheck className="mr-1 h-3 w-3 text-red-500" />
                            Reported
                          </span>
                        ) : (
                          <button
                            onClick={() => openReportPopup(transaction)}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none"
                          >
                            <FaExclamationTriangle className="mr-1 h-4 w-4 text-yellow-500" />
                            Report
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
      ) : errorMessage ? null : (
        <p className="text-gray-600">No transactions found.</p>
      )}
      </div>

      {isReportPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white rounded-lg p-6 z-10">
            <h2 className="text-xl font-bold mb-4">Report Message</h2>
            <textarea
              className="w-full h-32 border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your report message"
              value={reportMessage}
              onChange={(e) => setReportMessage(e.target.value)}
            ></textarea>
            {reportStatus === "success" && (
              <p className="text-green-500 mb-4">
                Report submitted successfully!
              </p>
            )}
            {reportStatus === "error" && (
              <p className="text-red-500 mb-4">
                Something went wrong. Please try again.
              </p>
            )}
            <div className="flex justify-end">
              <button
                onClick={closeReportPopup}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md mr-2 hover:bg-gray-300 focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={handleReportSubmit}
                className={`px-4 py-2 text-white border rounded-md focus:outline-none ${
                  reportMessage.trim() !== ""
                    ? "bg-primary border-primary hover:bg-primary-dark"
                    : "bg-gray-400 border-gray-400 cursor-not-allowed"
                }`}
                disabled={reportMessage.trim() === ""}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;