import React, { useState, useCallback, useEffect } from 'react';
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link';
import Plaid from '../../assets/logos/banks/svg/outss_Plaid.svg';
import Chase from '../../assets/logos/banks/svg/outss_Chase.svg';
import BankOfAmerica from '../../assets/logos/banks/svg/outss_BOA.svg';
import WellsFargo from '../../assets/logos/banks/svg/outss_WellsFargo.svg';
import Citibank from '../../assets/logos/banks/svg/outss_Citibank.svg';
import CapitalOne from '../../assets/logos/banks/svg/outss_CapitalOne.svg';
import HSBC from '../../assets/logos/banks/svg/outss_HSBC.svg';
import OutssLogo from '../../assets/logo.png';
import { MdLoop } from 'react-icons/md';
import { getApiBaseUrl, getServerUrl } from '../../config/constants';

interface Bank {
  name: string;
  logo: string;
}

const PlaidIntegrationConcise: React.FC = () => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [connectionState, setConnectionState] = useState<'initial' | 'connecting' | 'connected'>('initial');

  const banks: Bank[] = [
    { name: 'Chase', logo: Chase },
    { name: 'Bank of America', logo: BankOfAmerica },
    { name: 'Wells Fargo', logo: WellsFargo },
    { name: 'Citibank', logo: Citibank },
    { name: 'Capital One', logo: CapitalOne },
    { name: 'US Bank', logo: HSBC },
  ];

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const response = await fetch(getApiBaseUrl()+'/plaid/create_link_token/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken') || '',
          },
          body: JSON.stringify({ user_id: localStorage.getItem('userId') }),
        });
        const data = await response.json();
        setLinkToken(data.link_token);
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    };

    fetchLinkToken();
  }, []);

  const onSuccess: PlaidLinkOnSuccess = useCallback(async (public_token, metadata) => {
    setConnectionState('connecting');
    try {
      const response = await fetch(getApiBaseUrl()+'/plaid/exchange_public_token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': getCookie('csrftoken') || '',
        },
        body: `public_token=${encodeURIComponent(public_token)}&user_id=${localStorage.getItem('userId')}`,
      });
      const data = await response.json();
      console.log('Success:', data);
      setConnectionState('connected');
    } catch (error) {
      console.error('Error:', error);
      setConnectionState('initial');
    }
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  // Function to get CSRF token
  function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  }

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Connect Your Bank Account</h1>
        <div className="flex items-center">
          <img src={Plaid} alt="Plaid Logo" className="h-10" />
          <MdLoop className="text-black " />
          <img src={OutssLogo} alt="Outss Logo" className="h-4" />
        </div>
      </div>
      
      <p className="text-gray-600 mb-6">
      Securely link your bank account via Plaid's encrypted connection 
      for automatic, secure updates of your statements. No manual uploads needed!
      </p>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-3">Popular Banks</h2>
        <div className="grid grid-cols-3 gap-4">
          {banks.map((bank, index) => (
            <div key={index} className="bg-gray-100 p-3 rounded-lg flex items-center justify-center">
              <img src={bank.logo} alt={bank.name} className="max-w-full h-auto" />
            </div>
          ))}
        </div>
        <p className="text-gray-500 mt-2 text-sm text-center">Don't see your bank? 100+ more available. <a href="https://plaid.com/institutions/" className="text-primary hover:underline">See all banks</a></p>
      </div>

      {connectionState === 'initial' && (
        <button
          onClick={() => open()}
          disabled={!ready}
          className="w-full bg-primary hover:bg-primary text-white font-bold py-3 px-4 rounded transition duration-300"
        >
          Connect My Bank Account
        </button>
      )}

      {connectionState === 'connecting' && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4">
          <p className="text-yellow-700">Connecting to your bank...</p>
        </div>
      )}

      {connectionState === 'connected' && (
        <div className="bg-green-100 border-l-4 border-green-500 p-4">
          <p className="text-green-700">Your bank account is now connected!</p>
        </div>
      )}

      <div className="mt-6 text-xs text-gray-500 text-center">
        <p>By connecting, you agree to our <a href="#" className="text-primary hover:underline">Terms</a> and <a href="#" className="text-primary hover:underline">Privacy Policy</a>.</p>
        <p className="mt-2">🔒 Bank-grade encryption | Read-only access | 256-bit security</p>
      </div>
    </div>
  );
};

export default PlaidIntegrationConcise;