import axios from "axios";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FormData } from  "../../../pages/OnBoarding/hooks";
import LoadingScreen from "../../../components/LoadingScreen";
import OnBoarding from "../../../pages/OnBoarding";
import { getApiBaseUrl } from "../../../config/constants";

const MyProfile = () => {
  const [formData, setFormData] = useState<FormData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const businessId = localStorage.getItem("businessId");
        if (businessId) {
          const response = await axios.get(
            `${getApiBaseUrl()}/business-details/${businessId}/`
          );
          setFormData(response.data);
          setIsLoading(false);
        } else {
          setShowOnboarding(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = () => {
    setShowOnboarding(true);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (showOnboarding) {
    return <OnBoarding formData={formData} />;
  }

  if (!formData) {
    return <div>No data available.</div>;
  }

  return (
    <div className="w-full flex-col items-center bg-white mt-14 lg:mt-8">
      <div className="max-w-5xl ml-4 mt-6 px-4 h-3/4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-semibold mb-6">Account Details</h3>
          <button
            onClick={handleUpdate}
            className="text-gray-500 text-2xl mb-4 focus:outline-none"
          >
            <FaEdit color="#000" />
          </button>
        </div>

        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Business Details</h2>
            <hr className="mb-4" />
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">Business Name</p>
                <p>{formData.businessName}</p>
              </div>
              <div>
                <p className="text-gray-600">Legal Structure</p>
                <p>{formData.legalStructure}</p>
              </div>
              <div>
                <p className="text-gray-600">Industry Type</p>
                <p>{formData.industryType}</p>
              </div>
              <div>
                <p className="text-gray-600">Business Model</p>
                <p>{formData.businessModel}</p>
              </div>
              <div>
                <p className="text-gray-600">Accounting Software</p>
                <p>{formData.accountingSoftware}</p>
              </div>
              <div>
                <p className="text-gray-600">Chart of Accounts Status</p>
                <p>{formData.chartOfAccounts ? "Enabled" : "Disabled"}</p>
              </div>
              <div>
                <p className="text-gray-600">Tax ID Number</p>
                <p>{formData.taxIdNumber}</p>
              </div>
              <div>
                <p className="text-gray-600">Address</p>
                <p>{formData.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
