import { useEffect, useState } from "react";
import { BsCreditCard2Back, BsCreditCard2BackFill } from "react-icons/bs";
import { GoPerson, GoPersonFill, GoSignOut } from "react-icons/go";
import {
  RiBookFill,
  RiBookLine,
  RiCashFill,
  RiCashLine,
  RiFileList2Fill,
  RiFileList2Line,
  RiHome6Fill,
  RiHome6Line,
  RiInbox2Fill,
  RiInbox2Line,
  RiPieChart2Fill,
  RiPieChart2Line,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseLine,
} from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import logo from '../../../../assets/logo.png';

const Sidebar = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isBookkeepingOpen, setIsBookkeepingOpen] = useState(true);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const toggleSidebar = () => {
    if (isMobile) setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleBookkeeping = (e: any) => {
    e.stopPropagation();
    setIsBookkeepingOpen(!isBookkeepingOpen);
  };

  const toggleAccountSettings = (e:any) => {
    e.stopPropagation();
    setIsAccountSettingsOpen(!isAccountSettingsOpen)
  };  

  return (
    <div >
      {/* Mobile toggle button */}
      <button
        className="fixed top-4 left-4 z-50 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-20 bg-black bg-opacity-50 sidebarUser" 
          onClick={toggleSidebar}
        ></div>
      )}

      <aside
        className={`fixed top-0 left-0 z-50 w-64 h-screen transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0 bg-white border-r sidebar`}
        aria-label="Sidebar"
      >
        <div className="h-full bg-white dark:bg-gray-800">
          {/* Logo */}
          <div className="relative border-b justify-center items-center w-full h-20 p-2">
            {isSidebarOpen && <RiCloseLine className="absolute w-6 h-6 top-5 left-5 " onClick={toggleSidebar} />}
            <Link
              to="/"
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-center"
            >
              <img src={logo} alt="logo" width={120} />
            </Link>
          </div>

          {/* Menu items */}
          <ul className="space-y-2 font-medium px-4 pt-6" onClick={toggleSidebar}>
            {/* Dashboard */}
            <li className={location.pathname === "/dashboard" ? "border rounded-md bg-gray-200 dashboard-menu" : "dashboard-menu"}>
              <Link
                to="/dashboard"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/dashboard" ? "text-primary text-bold dashboard-menu" : "dashboard-menu"
                }`}
              >
                {location.pathname === "/dashboard" ? (
                  <RiHome6Fill className="w-6 h-6 text-primary" />
                ) : (
                  <RiHome6Line className="w-6 h-6 text-gray-700" />
                )}
                <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Dashboard</span>
              </Link>
            </li>

            {/* Bookkeeping */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-bookkeeping"
                data-collapse-toggle="dropdown-bookkeeping"
                onClick={toggleBookkeeping}
              >
                <RiBookLine className="w-6 h-6 text-gray-700" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Bookkeeping</span>
                {isBookkeepingOpen ? <RiArrowUpSLine className="w-6 h-6" /> : <RiArrowDownSLine className="w-6 h-6" />}
              </button>
              <ul id="dropdown-bookkeeping" className={`${isBookkeepingOpen ? "" : "hidden"} py-2 space-y-2`}>
                {/* Inbox */}
                <li className={location.pathname.includes("/inbox") ? "border rounded-md bg-gray-50 inbox-menu" : "inbox-menu"}>
                  <Link
                    to="/dashboard/inbox"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 inbox-menu group pl-11 ${
                      location.pathname.includes("/inbox") ? "text-primary text-bold " : ""
                    }`}
                  >
                    {location.pathname.includes("/inbox") ? (
                      <RiInbox2Fill className="w-6 h-6 text-primary" />
                    ) : (
                      <RiInbox2Line className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Inbox</span>
                  </Link>
                </li>

                {/* Income Statement */}
                <li className={location.pathname.includes("/income") ? "border rounded-md bg-gray-50 bankstatement-menu" : ""}>
                  <Link
                    to="/dashboard/income"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 income-menu group pl-11 ${
                      location.pathname.includes("/income") ? "text-primary text-bold " : ""
                    }`}
                  >
                    {location.pathname.includes("/income") ? (
                      <RiCashFill className="w-6 h-6 text-primary" />
                    ) : (
                      <RiCashLine className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Income Statement</span>
                  </Link>
                </li>

                {/* Balance Sheet */}
                <li className={location.pathname.includes("/balance") ? "border rounded-md bg-gray-50" : ""}>
                  <Link
                    to="/dashboard/balance"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 balance-menu group pl-11 ${
                      location.pathname.includes("/balance") ? "text-primary text-bold " : ""
                    }`}
                  >
                    {location.pathname.includes("/balance") ? (
                      <RiPieChart2Fill className="w-6 h-6 text-primary" />
                    ) : (
                      <RiPieChart2Line className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Balance Sheet</span>
                  </Link>
                </li>

                {/* My Books */}
                <li className={location.pathname.includes("/mybooks") ? "border rounded-md bg-gray-50" : ""}>
                  <Link
                    to="/dashboard/mybooks"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700  books-menu group pl-11 ${
                      location.pathname.includes("/mybooks") ? "text-primary text-bold" : ""
                    }`}
                  >
                    {location.pathname.includes("/mybooks") ? (
                      <RiBookFill className="w-6 h-6 text-primary" />
                    ) : (
                      <RiBookLine className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">My Books</span>
                  </Link>
                </li>

                {/* Transactions */}
                <li className={location.pathname.includes("/transactions") ? "border rounded-md bg-gray-50" : ""}>
                  <Link
                    to="/dashboard/transactions"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group transactions-menu pl-11 ${
                      location.pathname.includes("/transactions") ? "text-primary text-bold" : ""
                    }`}
                  >
                    {location.pathname.includes("/transactions") ? (
                      <RiFileList2Fill className="w-6 h-6 text-primary" />
                    ) : (
                      <RiFileList2Line className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Transactions</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* Account Settings */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 users-menu"
                aria-controls="dropdown-account"
                data-collapse-toggle="dropdown-account"
                onClick={toggleAccountSettings}
              >
                <GoPerson className="w-6 h-6 text-gray-700" />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Account Settings</span>
                {isAccountSettingsOpen ? <RiArrowUpSLine className="w-6 h-6" /> : <RiArrowDownSLine className="w-6 h-6" />}
              </button>
              <ul id="dropdown-account" className={`${isAccountSettingsOpen ? "" : "hidden"} py-2 space-y-2`}>
                {/* My Account */}
                <li className={location.pathname.includes("/myaccount") ? "border rounded-md bg-gray-50" : ""}>
                  <Link
                    to="/dashboard/myaccount"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group pl-11 ${
                      location.pathname.includes("/myaccount") ? "text-primary text-bold account-menu" : ""
                    }`}
                  >
                    {location.pathname.includes("/myaccount") ? (
                      <GoPersonFill className="w-6 h-6 text-primary" />
                    ) : (
                      <GoPerson className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">My Account</span>
                  </Link>
                </li>

                {/* Billing */}
                <li className={location.pathname.includes("/billing") ? "border rounded-md bg-gray-50" : ""}>
                  <Link
                    to="/dashboard/billing"
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group pl-11 ${
                      location.pathname.includes("/billing") ? "text-primary text-bold" : ""
                    }`}
                  >
                    {location.pathname.includes("/billing") ? (
                      <BsCreditCard2BackFill className="w-6 h-6 text-primary" />
                    ) : (
                      <BsCreditCard2Back className="w-6 h-6 text-gray-700" />
                    )}
                    <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Billing</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* Log out */}
            <li>
              <Link
                to="/dashboard/logout"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname.includes("/logout") ? "bg-blue-100 dark:bg-blue-900" : ""
                }`}
              ><GoSignOut className="w-6 h-6 text-gray-700" />
              <span className="mx-2 flex-1 ms-3 whitespace-nowrap">Log out</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  </div>
);
};

export default Sidebar;