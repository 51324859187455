import React, { useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Billings from "../Billings";
import ChatWindow from "../ChatWindow";
import MyBooks from "../MyBooks";
import MyProfile from "../MyProfile";
import DashboardSummary from "./components/DashboardSummary";
import Sidebar from "./components/SideBar";
import { StreamChat } from "stream-chat";
import WelcomeModal from "./components/WelcomeModal";
import PostTourDialog from "./components/PostTourDialog";

import BalanceSheet from "../Bookkeeping/BalanceSheet";
import Logout from "../../../pages/Logout";
import Statements from "../Bookkeeping/Statements";
import TransactionTable from "../Bookkeeping/TransactionTable";
import StreamChatHome from "../../../accountants/pages/StreamChatHome";
import axios from "axios";
import { getApiBaseUrl } from "../../../config/constants";
import { UserProgressType } from "./elements/types";
import mixpanel from "mixpanel-browser";
import { useMediaQuery } from "react-responsive";

const Dashboard = () => {
  const [showMenu, setShowMenu] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [runTour, setRunTour] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showPostTourDialog, setShowPostTourDialog] = useState(false);
  const [progressChecked, setProgressChecked] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowMenu(true);
      timeoutRef.current = setTimeout(() => {
        setShowMenu(false);
      }, 2000);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const userProgress = () => {
    
  
    const userId = localStorage.getItem("userId") || "1";
  
    axios.get<UserProgressType[]>(`${getApiBaseUrl()}/user-progress/?user=${userId}`)
      .then((response) => {
        if (response.data.length > 0) {
          console.log("User progress:", response.data);
          if (response.data[0].product_tour_completed) {
            setProgressChecked(true);
            localStorage.setItem("tourCompleted", "true");
          } else {
            setRunTour(true);
          }
        } else {
          // Handle case where no progress data is returned
          console.log("No user progress data found");
          setRunTour(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user progress:", error);
        // Optionally, you might want to set a default behavior here
        setRunTour(true);
      });
  };


  useEffect(() => {
    // Check if the tour has been completed before
    userProgress();
    const tourCompleted = localStorage.getItem("tourCompleted");
    if (!tourCompleted) {
      setShowWelcomeModal(true);
    }
  }, []);

  if (!localStorage.getItem("userId")) {
    return <Navigate to="/signin" />;
  }

  const menuItems: {
    text: string;
    done: boolean;
    link?: string;
  }[] = [
    { text: "Add Business Details", done: true, link: "/dashboard/myaccount" },
    { text: "Check Accountant Messages", done: false },
    { text: "Review your financial summary", done: false },
    { text: "Activate your complimentary trial", done: false },
  ];

  const steps = [
    {
      target: '.dashboard-menu',
      content: 'Click here to view your main dashboard summary.',
      disableBeacon: true,
    },
    {
      target: '.books-menu',
      content: 'Manage your books in this section.',
    },
    {
      target: '.inbox-menu',
      content: 'Check your messages and communicate with your dedicated accountant.',
    },
    {
      target: '.transactions-menu',
      content: 'View and manage your transactions.',
    },
    {
      target: '.balance-menu',
      content: 'View your balance sheet here.',
    },
    {
      target: '.profit-loss-menu',
      content: 'This graph shows your profit trends over time.',
    },
  ];

  const UpdateUserProdutTourCompleted = () => {
    // curl --location '127.0.0.1:8000/api/user-progress/update-product-tour/?user_id=29' \
    const userId = localStorage.getItem("userId") || "1";
    mixpanel.track('Product Tour Completed');
    const res = axios.get(`${getApiBaseUrl()}/user-progress/update-product-tour/?user_id=${userId}`)
      .then((response) => {
        console.log("User progress updated:", response.data);
      })
      .catch((error) => {
        console.error("Error updating user progress:", error);
      });
  }



  const handleJoyrideCallback = (data: any) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      // Store in local storage that the tour has been completed
      localStorage.setItem("tourCompleted", "true");
      UpdateUserProdutTourCompleted();
      // Show the PostTourDialog after the tour is completed
      setShowPostTourDialog(true);
    } else if (type === EVENTS.TOUR_END && status === STATUS.RUNNING) {
      // Handle the case when the user manually ends the tour
      setRunTour(false);
      localStorage.setItem("tourCompleted", "true");
      UpdateUserProdutTourCompleted();
      setShowPostTourDialog(true);
    }
    console.log('Joyride callback:', data);
  };

  const handleStartTour = () => {
    mixpanel.track('Product Tour Started');
    setShowWelcomeModal(false);
    // setRunTour(true);
  };

  const handleCloseWelcomeModal = () => {
    mixpanel.track('Product Tour Skipped Visited');
    setShowWelcomeModal(false);
    localStorage.setItem("tourCompleted", "true");
    setShowPostTourDialog(true);
  };

  const handleClosePostTourDialog = () => {
    setShowPostTourDialog(false);
    setShowWelcomeModal(false);
    console.log('Post tour dialog closed');
    localStorage.setItem("tourCompleted", "true");
  };

  return (
    <div className="bg-white flex">
      { !isMobile && progressChecked && !localStorage.getItem("tourCompleted") && (
        <WelcomeModal
          isOpen={showWelcomeModal}
          onClose={handleCloseWelcomeModal}
          onStartTour={handleStartTour}
        />
      )}
      {!isMobile &&
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideBackButton={true}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#69339C',
          },
        }}
        callback={handleJoyrideCallback}
        debug={true}
        locale={{
          last: 'Done'
        }}
      />}
      <PostTourDialog isOpen={showPostTourDialog} onClose={handleClosePostTourDialog} />
      <Sidebar />
      <div className="w-full md:w-full lg:w-3/4 bg-white lg:ml-64 md:ml-64">
        <Routes>
          <Route path="/" element={<DashboardSummary />} />
          <Route path="income" element={<Statements />} />
          <Route path="mybooks" element={<MyBooks />} />
          <Route path="balance" element={<BalanceSheet />} />
          <Route path="inbox" element={<StreamChatHome />} />
          <Route path="myaccount" element={<MyProfile />} />
          <Route path="logout" element={<Logout />} />
          <Route path="transactions" element={<TransactionTable />} />
          <Route path="billing" element={<Billings />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;