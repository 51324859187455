import { useEffect, useState } from "react";
import { FaExternalLinkAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegClipboard } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { getApiBaseUrl } from "../../../config/constants";
type AccountProps = {
  email: string;
  password: string;
  message: string;
};

export default function WorkMailAccountDisplay({}) {
  const [accountDetails, setAccountDetails] = useState<AccountProps | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userid");

  useEffect(() => {
    if (userId) {
      fetchAccountDetails();
    }
    return () => {};
  }, [userId]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${getApiBaseUrl()}/create-workmail-email/${userId}/`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (response.ok) {
        setAccountDetails(data);
      } else {
        setError(
          data.error || "An error occurred while fetching account details."
        );
      }
    } catch (err) {
      setError("Network error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const onRedirect = () =>
    (window.location.href =
      "https://webmail.mail.us-east-1.awsapps.com/workmail/?organization=outss");

  if (loading)
    return (
      <div className="p-4 max-w-md mx-auto my-10">
        <p>Creating work email account...</p>
      </div>
    );

  if (!loading && !accountDetails)
    return (
      <div className="p-4 max-w-md mx-auto my-10">
        <button
          onClick={fetchAccountDetails}
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Failed to create work email, Click to Retry
        </button>
      </div>
    );

  if (error)
    return (
      <div className="p-4 max-w-md mx-auto my-10">
        <p className="text-center text-red-500">{error}</p>
      </div>
    );

  return (
    <div className="p-4 max-w-md mx-auto bg-white rounded-xl shadow-md my-10">
      <div className="flex items-start gap-x-5 mb-4">
        <h2 className="text-2xl font-bold">WorkMail Account</h2>
        <FaExternalLinkAlt
          size={20}
          cursor={"pointer"}
          onClick={onRedirect}
          target="blank"
        />
      </div>

      {accountDetails && (
        <div className="mt-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email:
            </label>
            <div className="flex items-center">
              <input
                type="text"
                value={accountDetails.email}
                readOnly
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={() => copyToClipboard(accountDetails.email)}
                className="ml-2 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
              >
                {/* <ClipboardIcon className="h-5 w-5 text-gray-600" /> */}
                <FaRegClipboard size={24} />
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Password:
            </label>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                value={accountDetails.password}
                readOnly
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="ml-2 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
              >
                {showPassword ? (
                  // <EyeOffIcon className="h-5 w-5 text-gray-600" />
                  <FaRegEyeSlash size={24} />
                ) : (
                  // <EyeIcon className="h-5 w-5 text-gray-600" />
                  <FaRegEye size={24} />
                )}
              </button>
              <button
                onClick={() => copyToClipboard(accountDetails.password)}
                className="ml-2 bg-gray-200 hover:bg-gray-300 rounded-full p-2"
              >
                {/* <ClipboardIcon className="h-5 w-5 text-gray-600" /> */}
                <FaRegClipboard size={24} />
              </button>
            </div>
          </div>
          <p className="text-sm text-gray-600">{accountDetails.message}</p>
        </div>
      )}
    </div>
  );
}
